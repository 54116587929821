import { bookableDurationType } from "@/client/private";

export function getDuration(
  duration: string,
  durationType: bookableDurationType,
): string {
  if (durationType === bookableDurationType.UNTIL_OPERATION_CLOSED) {
    return "PT1H";
  }
  return duration;
}
