import { useFormContext } from "react-hook-form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import {
  getEnumValues,
  getFormFieldName,
  getSchemaProperties,
} from "@/components/form/utils";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";

type FormRadioGroupWithDescriptionProps<T extends ZodShape> =
  FormFieldProps<T> & {
    getValueLabel?: (value: string) => string;
    getValueDescription?: (value: string) => string;
    isDisabled?: (value: string) => boolean;
  };

export function FormRadioGroupWithDescription<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  getValueLabel,
  getValueDescription,
  isDisabled,
}: FormRadioGroupWithDescriptionProps<T>) {
  const form = useFormContext();

  const { label, isRequired, fieldSchema } = getSchemaProperties(schema, name);
  const formFieldName = getFormFieldName(path, name);
  const values = getEnumValues(fieldSchema);

  return (
    <FormField
      name={formFieldName}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <div className="mb-4">
            <FormItemLabel label={label} isRequired={isRequired} />
            <FormDescription>{description}</FormDescription>
          </div>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              value={field.value}
              className="flex flex-row flex-wrap gap-3"
            >
              {values.map((value) => (
                <Card key={value} className="w-full md:w-64 lg:w-96">
                  <CardHeader className="pb-3">
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem
                          value={value}
                          disabled={isDisabled?.(value)}
                        />
                      </FormControl>
                      <FormLabel
                        className={cn(
                          isDisabled?.(value) ? "text-muted-foreground" : "",
                          "font-normal",
                        )}
                      >
                        {getValueLabel?.(value) ?? value}
                      </FormLabel>
                    </FormItem>
                  </CardHeader>
                  <CardContent>
                    <FormDescription>
                      {getValueDescription?.(value)}
                    </FormDescription>
                  </CardContent>
                </Card>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
