import type { TFunction } from "i18next";
import {
  bookableDurationType,
  type BookableResponseDTO,
} from "@/client/private";
import {
  searchTermColumnId,
  type DataTableFilterField,
} from "@/types/table.ts";

export function getBookableTableFilters(
  t: TFunction,
): Array<DataTableFilterField<BookableResponseDTO>> {
  return [
    {
      value: searchTermColumnId,
      placeholder: t("bookables.search_placeholder"),
    },
    {
      label: t("bookables.properties.bookable_duration_type"),
      value: "bookableDurationType",
      options: Object.values(bookableDurationType).map((type) => {
        return {
          label: t(`bookables.bookable_duration_type.${type.toLowerCase()}`),
          value: type,
        };
      }),
    },
  ];
}
