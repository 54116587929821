import { useQuery } from "@tanstack/react-query";
import type {
  BookableResponseDTO,
  ReservationRequestResponseDTO,
  ResourceResponseDTO,
} from "@/client/private";
import {
  listBookablesOptions,
  listReservationsRequestsOptions,
  listResourcesOptions,
} from "@/client/private/@tanstack/react-query.gen";
import type {
  PlannerBookable,
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";

export function useBookableResourceRequestData() {
  const defaultPagination = {
    page: 0,
    size: 1000,
  };

  const { data: bookableData, isLoading: isBookablesLoading } = useQuery({
    ...listBookablesOptions({
      body: {
        pagination: defaultPagination,
        sort: { sortBy: "name", direction: "ASC" },
      },
    }),
  });

  const { data: resourceData, isLoading: isResourcesLoading } = useQuery({
    ...listResourcesOptions({
      body: {
        pagination: defaultPagination,
        sort: { sortBy: "name", direction: "ASC" },
      },
    }),
  });

  const { data: requestData, isLoading: isRequestsLoading } = useQuery({
    ...listReservationsRequestsOptions({
      body: {
        pagination: defaultPagination,
        sort: { sortBy: "startAt", direction: "ASC" },
      },
    }),
  });

  const bookables = mapBookables(bookableData?.content);
  const resources = mapResources(resourceData?.content);
  const requests = mapRequests(requestData?.content, bookables);

  return {
    bookables,
    resources,
    requests,
    isLoading: isBookablesLoading || isResourcesLoading || isRequestsLoading,
  };
}

function mapBookables(
  bookables: BookableResponseDTO[] | undefined,
): PlannerBookable[] {
  return bookables ?? [];
}

function mapResources(
  resources: ResourceResponseDTO[] | undefined,
): PlannerResource[] {
  return resources ?? [];
}

function mapRequests(
  requests: ReservationRequestResponseDTO[] | undefined,
  bookables: PlannerBookable[],
): PlannerReservationRequest[] {
  return (requests ?? []).map((request) => ({
    ...request,
    line: request.line.map((line) => {
      const bookable = bookables.find(({ id }) => id === line.bookableId);
      const { id: requestId, name, email } = request;
      return {
        ...line,
        requestId,
        name,
        email,
        requestedCapacity: line.capacity,
        capacityLabel: "",
        bookableName: bookable?.name ?? "",
      };
    }),
  }));
}
