import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import type { BookableCapacityItem } from "@/components/bookables/capacity-items/BookableCapacityItemTable";
import { BookableCapacityItemTable } from "@/components/bookables/capacity-items/BookableCapacityItemTable";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { FormMessage } from "@/components/ui/form";

export function BookableCapacityItems() {
  const { t } = useTranslation();
  const form = useFormContext<BookableSchemaValues>();

  const items = form.getValues("bookableCapacityItems");
  const itemValidationError = form.getFieldState("bookableCapacityItems").error
    ?.message;

  const handleAddItem = () => {
    const newItem: BookableCapacityItem = {
      id: crypto.randomUUID(),
      name: "",
    };
    form.setValue("bookableCapacityItems", [...items, newItem]);
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("bookables.capacity_items.title")}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col space-y-5">
        {itemValidationError && (
          <FormMessage className="pb-3">{itemValidationError}</FormMessage>
        )}
        <BookableCapacityItemTable />
        <Button type="button" className="self-end" onClick={handleAddItem}>
          {t("bookables.capacity_items.add")}
        </Button>
      </CardContent>
    </Card>
  );
}
