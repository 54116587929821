import {
  addHours,
  getHours,
  roundToNearestHours,
  startOfDay,
  subHours,
} from "date-fns";
import type { DateTimeFormat } from "@/types/date-time";

export type ZoomLevel = {
  id: string;
  titleUnits: string;
  titleCount?: number;
  getStartSlot: (now: Date) => Date;
  getDateSelectedStartSlot: (date: Date) => Date;
  slotSizeInMinutes: number;
  slotFormats: DateTimeFormat[];
};

export const zoomLevels: ZoomLevel[] = [
  {
    id: "0",
    titleUnits: "date_time.quarter",
    getStartSlot: (now: Date) => addHours(startOfDay(now), getHours(now) - 0.5),
    getDateSelectedStartSlot: (date) =>
      subHours(roundToNearestHours(date, { roundingMethod: "floor" }), 0.5),
    slotSizeInMinutes: 15,
    slotFormats: ["time"],
  },
  {
    id: "1",
    titleUnits: "date_time.half_hour",
    getStartSlot: (now: Date) => addHours(startOfDay(now), getHours(now) - 0.5),
    getDateSelectedStartSlot: (date) =>
      subHours(roundToNearestHours(date, { roundingMethod: "floor" }), 0.5),
    slotSizeInMinutes: 30,
    slotFormats: ["time"],
  },
  {
    id: "2",
    titleUnits: "date_time.hour",
    getStartSlot: (now: Date) => addHours(startOfDay(now), getHours(now) - 1),
    getDateSelectedStartSlot: (date) =>
      subHours(roundToNearestHours(date, { roundingMethod: "floor" }), 1),
    slotSizeInMinutes: 60,
    slotFormats: ["time"],
  },
  {
    id: "3",
    titleUnits: "date_time.hours",
    titleCount: 6,
    getStartSlot: (now: Date) =>
      addHours(startOfDay(now), Math.floor(getHours(now) / 6) - 1),
    getDateSelectedStartSlot: (date) =>
      subHours(
        roundToNearestHours(date, { roundingMethod: "floor", nearestTo: 6 }),
        6,
      ),
    slotSizeInMinutes: 6 * 60,
    slotFormats: ["dayOfMonthShort", "time"],
  },
  {
    id: "4",
    titleUnits: "date_time.hours",
    titleCount: 12,
    getStartSlot: (now: Date) => addHours(startOfDay(now), -12),
    getDateSelectedStartSlot: (date) => addHours(startOfDay(date), -12),
    slotSizeInMinutes: 12 * 60,
    slotFormats: ["dayOfMonthShort", "time"],
  },
  {
    id: "5",
    titleUnits: "date_time.day",
    getStartSlot: (now: Date) => addHours(startOfDay(now), -24),
    getDateSelectedStartSlot: (date) => addHours(startOfDay(date), -24),
    slotSizeInMinutes: 24 * 60,
    slotFormats: ["dayOfMonthShort"],
  },
];

export function getZoomLevel(id: string) {
  const result = zoomLevels.find((zoomLevel) => zoomLevel.id === id);
  if (!result) {
    throw new Error(`Zoom level with id ${id} not found.`);
  }
  return result;
}
