/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TermsImport } from './routes/terms'
import { Route as RegisterImport } from './routes/register'
import { Route as PrivacyImport } from './routes/privacy'
import { Route as LoginImport } from './routes/login'
import { Route as CallbackImport } from './routes/callback'
import { Route as AuthImport } from './routes/_auth'
import { Route as IndexImport } from './routes/index'
import { Route as AuthSupportImport } from './routes/_auth/support'
import { Route as AuthSettingsImport } from './routes/_auth/settings'
import { Route as AuthPlannerImport } from './routes/_auth/planner'
import { Route as AuthDashboardImport } from './routes/_auth/dashboard'
import { Route as AuthUnavailabilityIndexImport } from './routes/_auth/unavailability/index'
import { Route as AuthResourcesIndexImport } from './routes/_auth/resources/index'
import { Route as AuthReservationsIndexImport } from './routes/_auth/reservations/index'
import { Route as AuthEmployeesIndexImport } from './routes/_auth/employees/index'
import { Route as AuthChannelsIndexImport } from './routes/_auth/channels/index'
import { Route as AuthBookablesIndexImport } from './routes/_auth/bookables/index'
import { Route as AuthUnavailabilityAddImport } from './routes/_auth/unavailability/add'
import { Route as AuthUnavailabilityIdImport } from './routes/_auth/unavailability/$id'
import { Route as AuthSettingsProfileImport } from './routes/_auth/settings/profile'
import { Route as AuthSettingsGeneralImport } from './routes/_auth/settings/general'
import { Route as AuthResourcesAddImport } from './routes/_auth/resources/add'
import { Route as AuthResourcesIdImport } from './routes/_auth/resources/$id'
import { Route as AuthReservationsIdImport } from './routes/_auth/reservations/$id'
import { Route as AuthReservationRequestsAddImport } from './routes/_auth/reservation-requests/add'
import { Route as AuthReservationRequestsIdImport } from './routes/_auth/reservation-requests/$id'
import { Route as AuthEmployeesAddImport } from './routes/_auth/employees/add'
import { Route as AuthEmployeesIdImport } from './routes/_auth/employees/$id'
import { Route as AuthChannelsAddImport } from './routes/_auth/channels/add'
import { Route as AuthBookablesAddImport } from './routes/_auth/bookables/add'
import { Route as AuthBookablesIdImport } from './routes/_auth/bookables/$id'
import { Route as AuthSettingsOpeningHoursIndexImport } from './routes/_auth/settings/opening-hours/index'
import { Route as AuthChannelsIdIndexImport } from './routes/_auth/channels/$id/index'
import { Route as AuthSettingsOpeningHoursAddImport } from './routes/_auth/settings/opening-hours/add'
import { Route as AuthSettingsOpeningHoursIdImport } from './routes/_auth/settings/opening-hours/$id'
import { Route as AuthChannelsIdWidgetImport } from './routes/_auth/channels/$id/widget'

// Create/Update Routes

const TermsRoute = TermsImport.update({
  id: '/terms',
  path: '/terms',
  getParentRoute: () => rootRoute,
} as any)

const RegisterRoute = RegisterImport.update({
  id: '/register',
  path: '/register',
  getParentRoute: () => rootRoute,
} as any)

const PrivacyRoute = PrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => rootRoute,
} as any)

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const CallbackRoute = CallbackImport.update({
  id: '/callback',
  path: '/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthSupportRoute = AuthSupportImport.update({
  id: '/support',
  path: '/support',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSettingsRoute = AuthSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthRoute,
} as any)

const AuthPlannerRoute = AuthPlannerImport.update({
  id: '/planner',
  path: '/planner',
  getParentRoute: () => AuthRoute,
} as any)

const AuthDashboardRoute = AuthDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUnavailabilityIndexRoute = AuthUnavailabilityIndexImport.update({
  id: '/unavailability/',
  path: '/unavailability/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthResourcesIndexRoute = AuthResourcesIndexImport.update({
  id: '/resources/',
  path: '/resources/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReservationsIndexRoute = AuthReservationsIndexImport.update({
  id: '/reservations/',
  path: '/reservations/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthEmployeesIndexRoute = AuthEmployeesIndexImport.update({
  id: '/employees/',
  path: '/employees/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthChannelsIndexRoute = AuthChannelsIndexImport.update({
  id: '/channels/',
  path: '/channels/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthBookablesIndexRoute = AuthBookablesIndexImport.update({
  id: '/bookables/',
  path: '/bookables/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUnavailabilityAddRoute = AuthUnavailabilityAddImport.update({
  id: '/unavailability/add',
  path: '/unavailability/add',
  getParentRoute: () => AuthRoute,
} as any)

const AuthUnavailabilityIdRoute = AuthUnavailabilityIdImport.update({
  id: '/unavailability/$id',
  path: '/unavailability/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSettingsProfileRoute = AuthSettingsProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthSettingsGeneralRoute = AuthSettingsGeneralImport.update({
  id: '/general',
  path: '/general',
  getParentRoute: () => AuthSettingsRoute,
} as any)

const AuthResourcesAddRoute = AuthResourcesAddImport.update({
  id: '/resources/add',
  path: '/resources/add',
  getParentRoute: () => AuthRoute,
} as any)

const AuthResourcesIdRoute = AuthResourcesIdImport.update({
  id: '/resources/$id',
  path: '/resources/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReservationsIdRoute = AuthReservationsIdImport.update({
  id: '/reservations/$id',
  path: '/reservations/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthReservationRequestsAddRoute = AuthReservationRequestsAddImport.update(
  {
    id: '/reservation-requests/add',
    path: '/reservation-requests/add',
    getParentRoute: () => AuthRoute,
  } as any,
)

const AuthReservationRequestsIdRoute = AuthReservationRequestsIdImport.update({
  id: '/reservation-requests/$id',
  path: '/reservation-requests/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthEmployeesAddRoute = AuthEmployeesAddImport.update({
  id: '/employees/add',
  path: '/employees/add',
  getParentRoute: () => AuthRoute,
} as any)

const AuthEmployeesIdRoute = AuthEmployeesIdImport.update({
  id: '/employees/$id',
  path: '/employees/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthChannelsAddRoute = AuthChannelsAddImport.update({
  id: '/channels/add',
  path: '/channels/add',
  getParentRoute: () => AuthRoute,
} as any)

const AuthBookablesAddRoute = AuthBookablesAddImport.update({
  id: '/bookables/add',
  path: '/bookables/add',
  getParentRoute: () => AuthRoute,
} as any)

const AuthBookablesIdRoute = AuthBookablesIdImport.update({
  id: '/bookables/$id',
  path: '/bookables/$id',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSettingsOpeningHoursIndexRoute =
  AuthSettingsOpeningHoursIndexImport.update({
    id: '/opening-hours/',
    path: '/opening-hours/',
    getParentRoute: () => AuthSettingsRoute,
  } as any)

const AuthChannelsIdIndexRoute = AuthChannelsIdIndexImport.update({
  id: '/channels/$id/',
  path: '/channels/$id/',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSettingsOpeningHoursAddRoute =
  AuthSettingsOpeningHoursAddImport.update({
    id: '/opening-hours/add',
    path: '/opening-hours/add',
    getParentRoute: () => AuthSettingsRoute,
  } as any)

const AuthSettingsOpeningHoursIdRoute = AuthSettingsOpeningHoursIdImport.update(
  {
    id: '/opening-hours/$id',
    path: '/opening-hours/$id',
    getParentRoute: () => AuthSettingsRoute,
  } as any,
)

const AuthChannelsIdWidgetRoute = AuthChannelsIdWidgetImport.update({
  id: '/channels/$id/widget',
  path: '/channels/$id/widget',
  getParentRoute: () => AuthRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/callback': {
      id: '/callback'
      path: '/callback'
      fullPath: '/callback'
      preLoaderRoute: typeof CallbackImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/privacy': {
      id: '/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PrivacyImport
      parentRoute: typeof rootRoute
    }
    '/register': {
      id: '/register'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof RegisterImport
      parentRoute: typeof rootRoute
    }
    '/terms': {
      id: '/terms'
      path: '/terms'
      fullPath: '/terms'
      preLoaderRoute: typeof TermsImport
      parentRoute: typeof rootRoute
    }
    '/_auth/dashboard': {
      id: '/_auth/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AuthDashboardImport
      parentRoute: typeof AuthImport
    }
    '/_auth/planner': {
      id: '/_auth/planner'
      path: '/planner'
      fullPath: '/planner'
      preLoaderRoute: typeof AuthPlannerImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings': {
      id: '/_auth/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSettingsImport
      parentRoute: typeof AuthImport
    }
    '/_auth/support': {
      id: '/_auth/support'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof AuthSupportImport
      parentRoute: typeof AuthImport
    }
    '/_auth/bookables/$id': {
      id: '/_auth/bookables/$id'
      path: '/bookables/$id'
      fullPath: '/bookables/$id'
      preLoaderRoute: typeof AuthBookablesIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/bookables/add': {
      id: '/_auth/bookables/add'
      path: '/bookables/add'
      fullPath: '/bookables/add'
      preLoaderRoute: typeof AuthBookablesAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/channels/add': {
      id: '/_auth/channels/add'
      path: '/channels/add'
      fullPath: '/channels/add'
      preLoaderRoute: typeof AuthChannelsAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/employees/$id': {
      id: '/_auth/employees/$id'
      path: '/employees/$id'
      fullPath: '/employees/$id'
      preLoaderRoute: typeof AuthEmployeesIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/employees/add': {
      id: '/_auth/employees/add'
      path: '/employees/add'
      fullPath: '/employees/add'
      preLoaderRoute: typeof AuthEmployeesAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reservation-requests/$id': {
      id: '/_auth/reservation-requests/$id'
      path: '/reservation-requests/$id'
      fullPath: '/reservation-requests/$id'
      preLoaderRoute: typeof AuthReservationRequestsIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reservation-requests/add': {
      id: '/_auth/reservation-requests/add'
      path: '/reservation-requests/add'
      fullPath: '/reservation-requests/add'
      preLoaderRoute: typeof AuthReservationRequestsAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reservations/$id': {
      id: '/_auth/reservations/$id'
      path: '/reservations/$id'
      fullPath: '/reservations/$id'
      preLoaderRoute: typeof AuthReservationsIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/resources/$id': {
      id: '/_auth/resources/$id'
      path: '/resources/$id'
      fullPath: '/resources/$id'
      preLoaderRoute: typeof AuthResourcesIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/resources/add': {
      id: '/_auth/resources/add'
      path: '/resources/add'
      fullPath: '/resources/add'
      preLoaderRoute: typeof AuthResourcesAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings/general': {
      id: '/_auth/settings/general'
      path: '/general'
      fullPath: '/settings/general'
      preLoaderRoute: typeof AuthSettingsGeneralImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/settings/profile': {
      id: '/_auth/settings/profile'
      path: '/profile'
      fullPath: '/settings/profile'
      preLoaderRoute: typeof AuthSettingsProfileImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/unavailability/$id': {
      id: '/_auth/unavailability/$id'
      path: '/unavailability/$id'
      fullPath: '/unavailability/$id'
      preLoaderRoute: typeof AuthUnavailabilityIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/unavailability/add': {
      id: '/_auth/unavailability/add'
      path: '/unavailability/add'
      fullPath: '/unavailability/add'
      preLoaderRoute: typeof AuthUnavailabilityAddImport
      parentRoute: typeof AuthImport
    }
    '/_auth/bookables/': {
      id: '/_auth/bookables/'
      path: '/bookables'
      fullPath: '/bookables'
      preLoaderRoute: typeof AuthBookablesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/channels/': {
      id: '/_auth/channels/'
      path: '/channels'
      fullPath: '/channels'
      preLoaderRoute: typeof AuthChannelsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/employees/': {
      id: '/_auth/employees/'
      path: '/employees'
      fullPath: '/employees'
      preLoaderRoute: typeof AuthEmployeesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reservations/': {
      id: '/_auth/reservations/'
      path: '/reservations'
      fullPath: '/reservations'
      preLoaderRoute: typeof AuthReservationsIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/resources/': {
      id: '/_auth/resources/'
      path: '/resources'
      fullPath: '/resources'
      preLoaderRoute: typeof AuthResourcesIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/unavailability/': {
      id: '/_auth/unavailability/'
      path: '/unavailability'
      fullPath: '/unavailability'
      preLoaderRoute: typeof AuthUnavailabilityIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/channels/$id/widget': {
      id: '/_auth/channels/$id/widget'
      path: '/channels/$id/widget'
      fullPath: '/channels/$id/widget'
      preLoaderRoute: typeof AuthChannelsIdWidgetImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings/opening-hours/$id': {
      id: '/_auth/settings/opening-hours/$id'
      path: '/opening-hours/$id'
      fullPath: '/settings/opening-hours/$id'
      preLoaderRoute: typeof AuthSettingsOpeningHoursIdImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/settings/opening-hours/add': {
      id: '/_auth/settings/opening-hours/add'
      path: '/opening-hours/add'
      fullPath: '/settings/opening-hours/add'
      preLoaderRoute: typeof AuthSettingsOpeningHoursAddImport
      parentRoute: typeof AuthSettingsImport
    }
    '/_auth/channels/$id/': {
      id: '/_auth/channels/$id/'
      path: '/channels/$id'
      fullPath: '/channels/$id'
      preLoaderRoute: typeof AuthChannelsIdIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/settings/opening-hours/': {
      id: '/_auth/settings/opening-hours/'
      path: '/opening-hours'
      fullPath: '/settings/opening-hours'
      preLoaderRoute: typeof AuthSettingsOpeningHoursIndexImport
      parentRoute: typeof AuthSettingsImport
    }
  }
}

// Create and export the route tree

interface AuthSettingsRouteChildren {
  AuthSettingsGeneralRoute: typeof AuthSettingsGeneralRoute
  AuthSettingsProfileRoute: typeof AuthSettingsProfileRoute
  AuthSettingsOpeningHoursIdRoute: typeof AuthSettingsOpeningHoursIdRoute
  AuthSettingsOpeningHoursAddRoute: typeof AuthSettingsOpeningHoursAddRoute
  AuthSettingsOpeningHoursIndexRoute: typeof AuthSettingsOpeningHoursIndexRoute
}

const AuthSettingsRouteChildren: AuthSettingsRouteChildren = {
  AuthSettingsGeneralRoute: AuthSettingsGeneralRoute,
  AuthSettingsProfileRoute: AuthSettingsProfileRoute,
  AuthSettingsOpeningHoursIdRoute: AuthSettingsOpeningHoursIdRoute,
  AuthSettingsOpeningHoursAddRoute: AuthSettingsOpeningHoursAddRoute,
  AuthSettingsOpeningHoursIndexRoute: AuthSettingsOpeningHoursIndexRoute,
}

const AuthSettingsRouteWithChildren = AuthSettingsRoute._addFileChildren(
  AuthSettingsRouteChildren,
)

interface AuthRouteChildren {
  AuthDashboardRoute: typeof AuthDashboardRoute
  AuthPlannerRoute: typeof AuthPlannerRoute
  AuthSettingsRoute: typeof AuthSettingsRouteWithChildren
  AuthSupportRoute: typeof AuthSupportRoute
  AuthBookablesIdRoute: typeof AuthBookablesIdRoute
  AuthBookablesAddRoute: typeof AuthBookablesAddRoute
  AuthChannelsAddRoute: typeof AuthChannelsAddRoute
  AuthEmployeesIdRoute: typeof AuthEmployeesIdRoute
  AuthEmployeesAddRoute: typeof AuthEmployeesAddRoute
  AuthReservationRequestsIdRoute: typeof AuthReservationRequestsIdRoute
  AuthReservationRequestsAddRoute: typeof AuthReservationRequestsAddRoute
  AuthReservationsIdRoute: typeof AuthReservationsIdRoute
  AuthResourcesIdRoute: typeof AuthResourcesIdRoute
  AuthResourcesAddRoute: typeof AuthResourcesAddRoute
  AuthUnavailabilityIdRoute: typeof AuthUnavailabilityIdRoute
  AuthUnavailabilityAddRoute: typeof AuthUnavailabilityAddRoute
  AuthBookablesIndexRoute: typeof AuthBookablesIndexRoute
  AuthChannelsIndexRoute: typeof AuthChannelsIndexRoute
  AuthEmployeesIndexRoute: typeof AuthEmployeesIndexRoute
  AuthReservationsIndexRoute: typeof AuthReservationsIndexRoute
  AuthResourcesIndexRoute: typeof AuthResourcesIndexRoute
  AuthUnavailabilityIndexRoute: typeof AuthUnavailabilityIndexRoute
  AuthChannelsIdWidgetRoute: typeof AuthChannelsIdWidgetRoute
  AuthChannelsIdIndexRoute: typeof AuthChannelsIdIndexRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthDashboardRoute: AuthDashboardRoute,
  AuthPlannerRoute: AuthPlannerRoute,
  AuthSettingsRoute: AuthSettingsRouteWithChildren,
  AuthSupportRoute: AuthSupportRoute,
  AuthBookablesIdRoute: AuthBookablesIdRoute,
  AuthBookablesAddRoute: AuthBookablesAddRoute,
  AuthChannelsAddRoute: AuthChannelsAddRoute,
  AuthEmployeesIdRoute: AuthEmployeesIdRoute,
  AuthEmployeesAddRoute: AuthEmployeesAddRoute,
  AuthReservationRequestsIdRoute: AuthReservationRequestsIdRoute,
  AuthReservationRequestsAddRoute: AuthReservationRequestsAddRoute,
  AuthReservationsIdRoute: AuthReservationsIdRoute,
  AuthResourcesIdRoute: AuthResourcesIdRoute,
  AuthResourcesAddRoute: AuthResourcesAddRoute,
  AuthUnavailabilityIdRoute: AuthUnavailabilityIdRoute,
  AuthUnavailabilityAddRoute: AuthUnavailabilityAddRoute,
  AuthBookablesIndexRoute: AuthBookablesIndexRoute,
  AuthChannelsIndexRoute: AuthChannelsIndexRoute,
  AuthEmployeesIndexRoute: AuthEmployeesIndexRoute,
  AuthReservationsIndexRoute: AuthReservationsIndexRoute,
  AuthResourcesIndexRoute: AuthResourcesIndexRoute,
  AuthUnavailabilityIndexRoute: AuthUnavailabilityIndexRoute,
  AuthChannelsIdWidgetRoute: AuthChannelsIdWidgetRoute,
  AuthChannelsIdIndexRoute: AuthChannelsIdIndexRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/privacy': typeof PrivacyRoute
  '/register': typeof RegisterRoute
  '/terms': typeof TermsRoute
  '/dashboard': typeof AuthDashboardRoute
  '/planner': typeof AuthPlannerRoute
  '/settings': typeof AuthSettingsRouteWithChildren
  '/support': typeof AuthSupportRoute
  '/bookables/$id': typeof AuthBookablesIdRoute
  '/bookables/add': typeof AuthBookablesAddRoute
  '/channels/add': typeof AuthChannelsAddRoute
  '/employees/$id': typeof AuthEmployeesIdRoute
  '/employees/add': typeof AuthEmployeesAddRoute
  '/reservation-requests/$id': typeof AuthReservationRequestsIdRoute
  '/reservation-requests/add': typeof AuthReservationRequestsAddRoute
  '/reservations/$id': typeof AuthReservationsIdRoute
  '/resources/$id': typeof AuthResourcesIdRoute
  '/resources/add': typeof AuthResourcesAddRoute
  '/settings/general': typeof AuthSettingsGeneralRoute
  '/settings/profile': typeof AuthSettingsProfileRoute
  '/unavailability/$id': typeof AuthUnavailabilityIdRoute
  '/unavailability/add': typeof AuthUnavailabilityAddRoute
  '/bookables': typeof AuthBookablesIndexRoute
  '/channels': typeof AuthChannelsIndexRoute
  '/employees': typeof AuthEmployeesIndexRoute
  '/reservations': typeof AuthReservationsIndexRoute
  '/resources': typeof AuthResourcesIndexRoute
  '/unavailability': typeof AuthUnavailabilityIndexRoute
  '/channels/$id/widget': typeof AuthChannelsIdWidgetRoute
  '/settings/opening-hours/$id': typeof AuthSettingsOpeningHoursIdRoute
  '/settings/opening-hours/add': typeof AuthSettingsOpeningHoursAddRoute
  '/channels/$id': typeof AuthChannelsIdIndexRoute
  '/settings/opening-hours': typeof AuthSettingsOpeningHoursIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/privacy': typeof PrivacyRoute
  '/register': typeof RegisterRoute
  '/terms': typeof TermsRoute
  '/dashboard': typeof AuthDashboardRoute
  '/planner': typeof AuthPlannerRoute
  '/settings': typeof AuthSettingsRouteWithChildren
  '/support': typeof AuthSupportRoute
  '/bookables/$id': typeof AuthBookablesIdRoute
  '/bookables/add': typeof AuthBookablesAddRoute
  '/channels/add': typeof AuthChannelsAddRoute
  '/employees/$id': typeof AuthEmployeesIdRoute
  '/employees/add': typeof AuthEmployeesAddRoute
  '/reservation-requests/$id': typeof AuthReservationRequestsIdRoute
  '/reservation-requests/add': typeof AuthReservationRequestsAddRoute
  '/reservations/$id': typeof AuthReservationsIdRoute
  '/resources/$id': typeof AuthResourcesIdRoute
  '/resources/add': typeof AuthResourcesAddRoute
  '/settings/general': typeof AuthSettingsGeneralRoute
  '/settings/profile': typeof AuthSettingsProfileRoute
  '/unavailability/$id': typeof AuthUnavailabilityIdRoute
  '/unavailability/add': typeof AuthUnavailabilityAddRoute
  '/bookables': typeof AuthBookablesIndexRoute
  '/channels': typeof AuthChannelsIndexRoute
  '/employees': typeof AuthEmployeesIndexRoute
  '/reservations': typeof AuthReservationsIndexRoute
  '/resources': typeof AuthResourcesIndexRoute
  '/unavailability': typeof AuthUnavailabilityIndexRoute
  '/channels/$id/widget': typeof AuthChannelsIdWidgetRoute
  '/settings/opening-hours/$id': typeof AuthSettingsOpeningHoursIdRoute
  '/settings/opening-hours/add': typeof AuthSettingsOpeningHoursAddRoute
  '/channels/$id': typeof AuthChannelsIdIndexRoute
  '/settings/opening-hours': typeof AuthSettingsOpeningHoursIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_auth': typeof AuthRouteWithChildren
  '/callback': typeof CallbackRoute
  '/login': typeof LoginRoute
  '/privacy': typeof PrivacyRoute
  '/register': typeof RegisterRoute
  '/terms': typeof TermsRoute
  '/_auth/dashboard': typeof AuthDashboardRoute
  '/_auth/planner': typeof AuthPlannerRoute
  '/_auth/settings': typeof AuthSettingsRouteWithChildren
  '/_auth/support': typeof AuthSupportRoute
  '/_auth/bookables/$id': typeof AuthBookablesIdRoute
  '/_auth/bookables/add': typeof AuthBookablesAddRoute
  '/_auth/channels/add': typeof AuthChannelsAddRoute
  '/_auth/employees/$id': typeof AuthEmployeesIdRoute
  '/_auth/employees/add': typeof AuthEmployeesAddRoute
  '/_auth/reservation-requests/$id': typeof AuthReservationRequestsIdRoute
  '/_auth/reservation-requests/add': typeof AuthReservationRequestsAddRoute
  '/_auth/reservations/$id': typeof AuthReservationsIdRoute
  '/_auth/resources/$id': typeof AuthResourcesIdRoute
  '/_auth/resources/add': typeof AuthResourcesAddRoute
  '/_auth/settings/general': typeof AuthSettingsGeneralRoute
  '/_auth/settings/profile': typeof AuthSettingsProfileRoute
  '/_auth/unavailability/$id': typeof AuthUnavailabilityIdRoute
  '/_auth/unavailability/add': typeof AuthUnavailabilityAddRoute
  '/_auth/bookables/': typeof AuthBookablesIndexRoute
  '/_auth/channels/': typeof AuthChannelsIndexRoute
  '/_auth/employees/': typeof AuthEmployeesIndexRoute
  '/_auth/reservations/': typeof AuthReservationsIndexRoute
  '/_auth/resources/': typeof AuthResourcesIndexRoute
  '/_auth/unavailability/': typeof AuthUnavailabilityIndexRoute
  '/_auth/channels/$id/widget': typeof AuthChannelsIdWidgetRoute
  '/_auth/settings/opening-hours/$id': typeof AuthSettingsOpeningHoursIdRoute
  '/_auth/settings/opening-hours/add': typeof AuthSettingsOpeningHoursAddRoute
  '/_auth/channels/$id/': typeof AuthChannelsIdIndexRoute
  '/_auth/settings/opening-hours/': typeof AuthSettingsOpeningHoursIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/callback'
    | '/login'
    | '/privacy'
    | '/register'
    | '/terms'
    | '/dashboard'
    | '/planner'
    | '/settings'
    | '/support'
    | '/bookables/$id'
    | '/bookables/add'
    | '/channels/add'
    | '/employees/$id'
    | '/employees/add'
    | '/reservation-requests/$id'
    | '/reservation-requests/add'
    | '/reservations/$id'
    | '/resources/$id'
    | '/resources/add'
    | '/settings/general'
    | '/settings/profile'
    | '/unavailability/$id'
    | '/unavailability/add'
    | '/bookables'
    | '/channels'
    | '/employees'
    | '/reservations'
    | '/resources'
    | '/unavailability'
    | '/channels/$id/widget'
    | '/settings/opening-hours/$id'
    | '/settings/opening-hours/add'
    | '/channels/$id'
    | '/settings/opening-hours'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/callback'
    | '/login'
    | '/privacy'
    | '/register'
    | '/terms'
    | '/dashboard'
    | '/planner'
    | '/settings'
    | '/support'
    | '/bookables/$id'
    | '/bookables/add'
    | '/channels/add'
    | '/employees/$id'
    | '/employees/add'
    | '/reservation-requests/$id'
    | '/reservation-requests/add'
    | '/reservations/$id'
    | '/resources/$id'
    | '/resources/add'
    | '/settings/general'
    | '/settings/profile'
    | '/unavailability/$id'
    | '/unavailability/add'
    | '/bookables'
    | '/channels'
    | '/employees'
    | '/reservations'
    | '/resources'
    | '/unavailability'
    | '/channels/$id/widget'
    | '/settings/opening-hours/$id'
    | '/settings/opening-hours/add'
    | '/channels/$id'
    | '/settings/opening-hours'
  id:
    | '__root__'
    | '/'
    | '/_auth'
    | '/callback'
    | '/login'
    | '/privacy'
    | '/register'
    | '/terms'
    | '/_auth/dashboard'
    | '/_auth/planner'
    | '/_auth/settings'
    | '/_auth/support'
    | '/_auth/bookables/$id'
    | '/_auth/bookables/add'
    | '/_auth/channels/add'
    | '/_auth/employees/$id'
    | '/_auth/employees/add'
    | '/_auth/reservation-requests/$id'
    | '/_auth/reservation-requests/add'
    | '/_auth/reservations/$id'
    | '/_auth/resources/$id'
    | '/_auth/resources/add'
    | '/_auth/settings/general'
    | '/_auth/settings/profile'
    | '/_auth/unavailability/$id'
    | '/_auth/unavailability/add'
    | '/_auth/bookables/'
    | '/_auth/channels/'
    | '/_auth/employees/'
    | '/_auth/reservations/'
    | '/_auth/resources/'
    | '/_auth/unavailability/'
    | '/_auth/channels/$id/widget'
    | '/_auth/settings/opening-hours/$id'
    | '/_auth/settings/opening-hours/add'
    | '/_auth/channels/$id/'
    | '/_auth/settings/opening-hours/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthRoute: typeof AuthRouteWithChildren
  CallbackRoute: typeof CallbackRoute
  LoginRoute: typeof LoginRoute
  PrivacyRoute: typeof PrivacyRoute
  RegisterRoute: typeof RegisterRoute
  TermsRoute: typeof TermsRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthRoute: AuthRouteWithChildren,
  CallbackRoute: CallbackRoute,
  LoginRoute: LoginRoute,
  PrivacyRoute: PrivacyRoute,
  RegisterRoute: RegisterRoute,
  TermsRoute: TermsRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.ts",
      "children": [
        "/",
        "/_auth",
        "/callback",
        "/login",
        "/privacy",
        "/register",
        "/terms"
      ]
    },
    "/": {
      "filePath": "index.ts"
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/dashboard",
        "/_auth/planner",
        "/_auth/settings",
        "/_auth/support",
        "/_auth/bookables/$id",
        "/_auth/bookables/add",
        "/_auth/channels/add",
        "/_auth/employees/$id",
        "/_auth/employees/add",
        "/_auth/reservation-requests/$id",
        "/_auth/reservation-requests/add",
        "/_auth/reservations/$id",
        "/_auth/resources/$id",
        "/_auth/resources/add",
        "/_auth/unavailability/$id",
        "/_auth/unavailability/add",
        "/_auth/bookables/",
        "/_auth/channels/",
        "/_auth/employees/",
        "/_auth/reservations/",
        "/_auth/resources/",
        "/_auth/unavailability/",
        "/_auth/channels/$id/widget",
        "/_auth/channels/$id/"
      ]
    },
    "/callback": {
      "filePath": "callback.ts"
    },
    "/login": {
      "filePath": "login.ts"
    },
    "/privacy": {
      "filePath": "privacy.ts"
    },
    "/register": {
      "filePath": "register.ts"
    },
    "/terms": {
      "filePath": "terms.ts"
    },
    "/_auth/dashboard": {
      "filePath": "_auth/dashboard.tsx",
      "parent": "/_auth"
    },
    "/_auth/planner": {
      "filePath": "_auth/planner.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings": {
      "filePath": "_auth/settings.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/settings/general",
        "/_auth/settings/profile",
        "/_auth/settings/opening-hours/$id",
        "/_auth/settings/opening-hours/add",
        "/_auth/settings/opening-hours/"
      ]
    },
    "/_auth/support": {
      "filePath": "_auth/support.tsx",
      "parent": "/_auth"
    },
    "/_auth/bookables/$id": {
      "filePath": "_auth/bookables/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/bookables/add": {
      "filePath": "_auth/bookables/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/channels/add": {
      "filePath": "_auth/channels/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/employees/$id": {
      "filePath": "_auth/employees/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/employees/add": {
      "filePath": "_auth/employees/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/reservation-requests/$id": {
      "filePath": "_auth/reservation-requests/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/reservation-requests/add": {
      "filePath": "_auth/reservation-requests/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/reservations/$id": {
      "filePath": "_auth/reservations/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/resources/$id": {
      "filePath": "_auth/resources/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/resources/add": {
      "filePath": "_auth/resources/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/general": {
      "filePath": "_auth/settings/general.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/settings/profile": {
      "filePath": "_auth/settings/profile.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/unavailability/$id": {
      "filePath": "_auth/unavailability/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/unavailability/add": {
      "filePath": "_auth/unavailability/add.tsx",
      "parent": "/_auth"
    },
    "/_auth/bookables/": {
      "filePath": "_auth/bookables/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/channels/": {
      "filePath": "_auth/channels/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/employees/": {
      "filePath": "_auth/employees/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/reservations/": {
      "filePath": "_auth/reservations/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/resources/": {
      "filePath": "_auth/resources/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/unavailability/": {
      "filePath": "_auth/unavailability/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/channels/$id/widget": {
      "filePath": "_auth/channels/$id/widget.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/opening-hours/$id": {
      "filePath": "_auth/settings/opening-hours/$id.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/settings/opening-hours/add": {
      "filePath": "_auth/settings/opening-hours/add.tsx",
      "parent": "/_auth/settings"
    },
    "/_auth/channels/$id/": {
      "filePath": "_auth/channels/$id/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/settings/opening-hours/": {
      "filePath": "_auth/settings/opening-hours/index.tsx",
      "parent": "/_auth/settings"
    }
  }
}
ROUTE_MANIFEST_END */
