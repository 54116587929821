import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { OpeningHourResponseDTO } from "@/client/private";
import { updateOpeningHoursMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { OperationSelect } from "@/components/opening-hours/OperationSelect";
import type { OpeningHourFormData } from "@/components/opening-hours/models/opening-hour-form-data.model";
import { RecurrenceForm } from "@/components/recurrence/RecurrenceForm";
import { createRecurringRule } from "@/components/recurrence/functions/create-recurring-rule";
import {
  getDurationFromStartAndEndTime,
  getEndTimeFromStartAndDuration,
} from "@/components/recurrence/functions/duration";
import { parseRecurringRule } from "@/components/recurrence/functions/parse-rule";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";
import { useDateTime } from "@/hooks/use-date-time";

type EditOpeningHourFormProps = {
  openingHour: OpeningHourResponseDTO;
};

export function EditOpeningHourForm({ openingHour }: EditOpeningHourFormProps) {
  const { t } = useTranslation();
  const { today, formatDate, setTime } = useDateTime();
  const navigate = useNavigate();
  const update = useMutation({
    ...updateOpeningHoursMutation(),
  });

  const rule = useMemo(
    () => parseRecurringRule(openingHour.recurringRule, formatDate),
    [openingHour.recurringRule, formatDate],
  );
  const endTime = useMemo(
    () =>
      getEndTimeFromStartAndDuration(
        rule.startTime,
        openingHour.recurringDuration,
      ),
    [rule.startTime, openingHour.recurringDuration],
  );

  const form = useForm<OpeningHourFormData>({
    values: {
      startDate: openingHour.startAt ?? today(),
      endDate: rule.endDate,
      startTime: rule.startTime,
      endTime,
      frequency: rule.frequency,
      interval: rule.interval,
      weekDays: rule.weekDays,
      operation: openingHour.operation,
    },
    mode: "onChange",
  });

  const handleSubmit = (values: OpeningHourFormData) => {
    update.mutate(
      {
        path: { id: openingHour.id },
        body: {
          ...openingHour,
          startAt: setTime(values.startDate, values.startTime),
          recurringRule: createRecurringRule(values, setTime),
          recurringDuration: getDurationFromStartAndEndTime(
            values.startTime,
            values.endTime,
          ),
          operation: values.operation,
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("opening_hours.opening_hour") }),
          );
          navigate({ to: "/settings/opening-hours" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("opening_hours.opening_hour") }),
          ),
      },
    );
  };

  const buttons = (
    <Button
      disabled={update.isPending || !form.formState.isValid}
      onClick={() => handleSubmit(form.getValues())}
    >
      {t("common.save")}
    </Button>
  );

  return (
    <EditItemContainer title={t("opening_hours.edit.title")} buttons={buttons}>
      <Card>
        <CardHeader>
          <CardTitle>{t("opening_hours.opening_hour")}</CardTitle>
          <CardDescription>
            {t("opening_hours.edit.description")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form {...form}>
            <form className="space-y-5">
              <FormField
                control={form.control}
                name="operation"
                render={({ field }) => (
                  <OperationSelect
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              ></FormField>
              <RecurrenceForm />
            </form>
          </Form>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
