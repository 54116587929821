// Shape of the environment variable created on the window object at runtime
type EnvConfig = {
  api: {
    platformUrl: string;
    clientUrl: string;
  };
  auth: {
    authority: string;
    clientId: string;
    redirectUri: string;
  };
};

// Declare the cbrmEnv property on the window object
declare global {
  interface Window {
    cbrmEnv?: EnvConfig;
  }
}

export function getEnvConfig(): EnvConfig {
  if (import.meta.env.DEV) {
    return configMap.dev;
  } else if (window.location.hostname === "staging.cbrm.cloud") {
    return configMap.staging;
  } else {
    return configMap.production;
  }
}

type Env = "dev" | "staging" | "production";

const configMap: Record<Env, EnvConfig> = {
  dev: {
    api: {
      platformUrl: "https://management.api.cbrm.cloud/api",
      clientUrl: "https://client.api.cbrm.cloud/api",
    },
    auth: {
      authority: "https://sso.cbrm.cloud/realms/cbrm/",
      clientId: "platform-frontend-client",
      redirectUri: "http://localhost:5555",
    },
  },
  staging: {
    api: {
      platformUrl: "https://management.api.cbrm.cloud/api",
      clientUrl: "https://client.api.cbrm.cloud/api",
    },
    auth: {
      authority: "https://sso.cbrm.cloud/realms/cbrm/",
      clientId: "platform-frontend-client",
      redirectUri: "https://app.cbrm.cloud",
    },
  },
  production: {
    api: {
      platformUrl: "https://management.api.cbrm.cloud/api",
      clientUrl: "https://client.api.cbrm.cloud/api",
    },
    auth: {
      authority: "https://sso.cbrm.cloud/realms/cbrm/",
      clientId: "platform-frontend-client",
      redirectUri: "https://app.cbrm.cloud",
    },
  },
};
