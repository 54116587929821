import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { Row } from "@tanstack/react-table";
import React from "react";
import { useTranslation } from "react-i18next";
import type { DataTableActionHandlers } from "@/components/data-table/cells/data-table-action-handlers";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

type ActionItemProps<T> = {
  key: string;
  onClick: (row: Row<T>) => void;
  text: string;
};

export type DataTableActionCellProps<T> = {
  row: Row<T>;
  actionHandlers: DataTableActionHandlers<T>;
  actionItems?: ActionItemProps<T>[];
};

export const DataTableActionCell = <T,>({
  row,
  actionHandlers,
  actionItems = [],
}: DataTableActionCellProps<T>) => {
  const { t } = useTranslation();
  const { handleEdit, handleDelete } = actionHandlers;
  const { confirm: confirmDelete, Dialog: ConfirmDeleteDialog } =
    useConfirmDialog();

  const handleDeleteWithConfirmation = () => {
    confirmDelete().then((confirmed) => {
      if (confirmed) {
        handleDelete?.(row);
      }
    });
  };

  const allActionItems: {
    key: string;
    onClick: (row: Row<T>) => void;
    text: string;
  }[] = [...actionItems];

  if (handleEdit) {
    allActionItems.push({
      key: "edit",
      onClick: () => handleEdit(row),
      text: t("common.edit"),
    });
  }

  if (handleDelete) {
    allActionItems.push({
      key: "delete",
      onClick: handleDeleteWithConfirmation,
      text: t("common.delete"),
    });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            aria-label={t("table.open_menu_label")}
            variant="ghost"
            className="flex size-8 p-0 data-[state=open]:bg-muted"
          >
            <DotsHorizontalIcon className="size-4" aria-hidden="true" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-40">
          {allActionItems.map(({ key, onClick, text }, index) => (
            <React.Fragment key={key}>
              {index > 0 && <DropdownMenuSeparator />}
              <DropdownMenuItem onClick={() => onClick(row)}>
                {text}
              </DropdownMenuItem>
            </React.Fragment>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmDeleteDialog
        texts={{
          cancel: t("common.no"),
          confirm: t("common.yes"),
          description: t("common.confirm_delete"),
        }}
      />
    </>
  );
};
