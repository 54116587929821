import { useNavigate } from "@tanstack/react-router";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button.tsx";
import { Icons } from "@/components/ui/icons.tsx";
import { AuthenticationContext } from "@/context/AuthenticationContext.tsx";

export function LoginForm() {
  const navigate = useNavigate();
  const { userManager } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  return (
    <>
      <Button
        className="ml-auto w-full"
        type="button"
        onClick={() => userManager.signinRedirect()}
      >
        {t("login.login")}
      </Button>

      <Button
        className="w-full"
        variant="outline"
        type="button"
        onClick={() => navigate({ to: "/register" })}
      >
        <Icons.add className="mr-2 size-4" />
        {t("login.sign_up")}
      </Button>
    </>
  );
}
