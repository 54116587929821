import { ReservationDetails } from "@/components/planner/ReservationDetails";
import {
  getPlannerItemId,
  type GridLayout,
} from "@/components/planner/grid-layout";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationLine,
} from "@/components/planner/planner.model";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

type ReservationProps = {
  reservationLine:
    | PlannerReservationLine
    | AssignedPlannerReservationRequestLine;
  isRequest: boolean;
  gridLayout: GridLayout;
};

export default function ReservationCell({
  reservationLine,
  isRequest,
  gridLayout,
}: ReservationProps) {
  const gridRow = gridLayout.getRow(
    reservationLine.resourceId,
    reservationLine.id,
  );

  const columnStyle = gridLayout.getTimeSpanColumnStyle(reservationLine);
  const backgroundStyle = !isRequest ? "bg-sky-600/75" : "bg-sky-900";

  if (
    !columnStyle ||
    !gridRow ||
    !gridLayout.overlapsTimeSlots(reservationLine)
  ) {
    return null;
  }

  return (
    <Popover>
      <PopoverTrigger asChild className="cursor-pointer">
        <div
          key={getPlannerItemId(reservationLine.resourceId, reservationLine.id)}
          style={{
            ...columnStyle,
            gridRow: `${gridRow} / span 1`,
          }}
          className={`border-2 border-muted-foreground rounded-md min-w-0 min-h-0 ${backgroundStyle}`}
        >
          <div className="p-2 text-foreground">
            <div className="text-sm text-ellipsis overflow-hidden whitespace-nowrap">
              {reservationLine.name}
            </div>
            <div className="text-xs text-ellipsis overflow-hidden whitespace-nowrap">
              {reservationLine.bookableName} ({reservationLine.capacity})
            </div>
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <ReservationDetails reservation={reservationLine} />
      </PopoverContent>
    </Popover>
  );
}
