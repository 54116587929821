import type { SelectProps } from "@radix-ui/react-select";
import { useId, type ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import {
  getEnumValues,
  getFormFieldName,
  getSchemaProperties,
} from "@/components/form/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type FormSelectProps<T extends ZodShape> = SelectProps &
  FormFieldProps<T> & {
    placeholder?: ReactNode;
    getValueLabel?: (value: string) => string;
  };

export function FormSelect<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  getValueLabel,
  ...props
}: FormSelectProps<T>) {
  const { t } = useTranslation();
  const form = useFormContext();
  const id = useId();

  const { label, isRequired, fieldSchema } = getSchemaProperties(schema, name);
  const values = getEnumValues(fieldSchema);

  function findItem(value: string) {
    return values.find((item) => item === value);
  }

  function getSafeValueLabel(value: string | undefined) {
    return getValueLabel ? getValueLabel(value ?? "") : value;
  }

  return (
    <FormField
      name={getFormFieldName(path, name)}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <FormItemLabel label={label} isRequired={isRequired} htmlFor={id} />
          <FormControl>
            <Select
              onValueChange={field.onChange}
              value={field.value ?? ""}
              {...props}
            >
              <SelectTrigger id={id}>
                <SelectValue>
                  {field.value
                    ? getSafeValueLabel(findItem(field.value))
                    : t("common.select_option")}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                {values.map((value) => (
                  <SelectItem value={value} key={value}>
                    {getSafeValueLabel(value)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
