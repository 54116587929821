import { useTranslation } from "react-i18next";
import type { createBookableSchema } from "@/components/bookables/bookable-schema";
import { useChannelData } from "@/components/bookables/channel-selection/use-channel-data";
import { FormCheckboxGroup } from "@/components/form";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

type ChannelSelectionProps = {
  schema: ReturnType<typeof createBookableSchema>;
};

export function ChannelSelection({ schema }: ChannelSelectionProps) {
  const { t } = useTranslation();
  const { channelOptions, isChannelsPending } = useChannelData();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("bookables.properties.channels")}</CardTitle>
      </CardHeader>
      <CardContent>
        <FormCheckboxGroup
          name="channelId"
          schema={schema}
          options={channelOptions}
          isPending={isChannelsPending}
          hideLabel={true}
          description={
            channelOptions.length === 0 && t("bookables.add.no_channels")
          }
        />
      </CardContent>
    </Card>
  );
}
