import { useCallback, useEffect, useState } from "react";
import ResourceReservationGrid from "@/components/planner/ResourceReservationGrid";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequest,
} from "@/components/planner/planner.model";
import { ReservationRequests } from "@/components/planner/requests/ReservationRequests";

export default function PlannerWithRequests() {
  const [hoveredRequests, setHoveredRequests] = useState<
    PlannerReservationRequest[]
  >([]);
  const [assignedRequestLines, setAssignedRequestLines] = useState<
    AssignedPlannerReservationRequestLine[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined);

  const handleDocumentHover = useCallback(() => {
    if (!hoveredRequests.length) {
      return;
    }
    setHoveredRequests([]);
  }, [hoveredRequests]);

  useEffect(() => {
    document.addEventListener("mouseover", handleDocumentHover);
    return () => {
      document.removeEventListener("mouseover", handleDocumentHover);
    };
  }, [handleDocumentHover]);

  return (
    <div className="grid grid-rows-1 xl:grid-cols-[1fr_auto] gap-2 px-4 max-h-full">
      <ResourceReservationGrid
        hoveredRequests={hoveredRequests}
        requestLines={assignedRequestLines}
        initialDate={selectedDate}
      />
      <ReservationRequests
        onHoveredRequestsChange={setHoveredRequests}
        onAssignedRequestsChange={setAssignedRequestLines}
        onCalendarSyncClick={(request) => setSelectedDate(request.startAt)}
      />
    </div>
  );
}
