import { useTranslation } from "react-i18next";
import type { createBookableSchema } from "@/components/bookables/bookable-schema";
import { useResourceData } from "@/components/bookables/resource-selection/use-resource-data";
import { FormCheckboxGroup } from "@/components/form";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

type ResourceSelectionProps = {
  schema: ReturnType<typeof createBookableSchema>;
};

export function ResourceSelection({ schema }: ResourceSelectionProps) {
  const { t } = useTranslation();
  const { resourceOptions, isResourcePending } = useResourceData();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("bookables.properties.resources")}</CardTitle>
      </CardHeader>
      <CardContent>
        <FormCheckboxGroup
          name="resourceId"
          schema={schema}
          options={resourceOptions}
          isPending={isResourcePending}
          hideLabel={true}
          description={
            resourceOptions.length === 0 && t("bookables.add.no_resources")
          }
        />
      </CardContent>
    </Card>
  );
}
