import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

type SearchInputProps = {
  value: string;
  placeholder?: string;
  maxLength?: number;
  className?: string;
  onChange: (value: string) => void;
};

export function SearchInput({
  value,
  placeholder,
  maxLength,
  className,
  onChange,
}: SearchInputProps) {
  return (
    <div className="relative flex-1 flex items-center md:grow-0">
      <Search className="absolute left-2.5 size-4 text-muted-foreground" />
      <Input
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        type="search"
        className={cn("w-full rounded-lg bg-background pl-8 h-8", className)}
      />
    </div>
  );
}
