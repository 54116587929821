import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { bookableDurationType } from "@/client/private";
import { createBookablesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import {
  createBookableSchema,
  timeSlotAssignmentType,
} from "@/components/bookables/bookable-schema";
import { BookableCapacityItems } from "@/components/bookables/capacity-items/BookableCapacityItems";
import { ChannelSelection } from "@/components/bookables/channel-selection/ChannelSelection";
import { getDuration } from "@/components/bookables/duration-capacity-type";
import { ResourceSelection } from "@/components/bookables/resource-selection/ResourceSelection";
import {
  FormContainer,
  FormDuration,
  FormInput,
  FormSubmit,
  FormTextArea,
  useZodForm,
} from "@/components/form";
import { FormRadioGroupWithDescription } from "@/components/form/fields/FormRadioGroupWithDescription";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddBookableForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createBookablesMutation(),
  });

  const schema = createBookableSchema(t);
  const form = useZodForm({ schema });
  const durationType = form.watch("bookableDurationType");

  const handleSubmit = (values: BookableSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          resourceId: values.resourceId ?? [],
          channelId: values.channelId ?? [],
          storage: [],
          duration: getDuration(values.duration, values.bookableDurationType),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.create_success", { item: t("bookables.bookable") }),
          );
          navigate({ to: "/bookables" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={create}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <FormContainer form={form} onSubmit={handleSubmit}>
      <EditItemContainer
        title={t("bookables.add.title")}
        buttons={buttons}
        sidePanel={
          <>
            <BookableCapacityItems />
            <ChannelSelection schema={schema} />
            <ResourceSelection schema={schema} />
          </>
        }
      >
        <Card>
          <CardHeader>
            <CardTitle>{t("bookables.bookable")}</CardTitle>
            <CardDescription>{t("bookables.add.description")}</CardDescription>
          </CardHeader>
          <CardContent className="space-y-5">
            <FormInput name="name" schema={schema} />
            <FormTextArea name="description" schema={schema} />
            <FormInput name="capacityLabel" schema={schema} />
            <FormRadioGroupWithDescription
              name="bookableDurationType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.bookable_duration_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.bookable_duration_type.${value.toLowerCase()}_description`,
                )
              }
              isDisabled={(value) => value !== bookableDurationType.FIXED}
            />
            {durationType !== bookableDurationType.UNTIL_OPERATION_CLOSED && (
              <FormDuration
                name="duration"
                schema={schema}
                label={
                  durationType === bookableDurationType.UNTIL_USER_DEFINED
                    ? t("bookables.properties.min_duration")
                    : t("bookables.properties.duration")
                }
              />
            )}
            <FormInput name="maxCapacity" schema={schema} />
            <FormRadioGroupWithDescription
              name="capacityBlockType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.capacity_block_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.capacity_block_type.${value.toLowerCase()}_description`,
                )
              }
            />
            <FormDuration name="slotInterval" schema={schema} />
            <FormRadioGroupWithDescription
              name="timeSlotAssignmentType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.time_slot_assignment_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.time_slot_assignment_type.${value.toLowerCase()}_description`,
                )
              }
              isDisabled={(value) => value !== timeSlotAssignmentType.SINGLE}
            />
          </CardContent>
        </Card>
      </EditItemContainer>
    </FormContainer>
  );
}
