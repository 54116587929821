import { createRootRouteWithContext } from "@tanstack/react-router";
import NotFound from "@/pages/NotFound.tsx";

interface RouterContext {
  sub?: string;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  notFoundComponent: NotFound,
});
