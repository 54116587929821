import { useMutation } from "@tanstack/react-query";
import type { Row } from "@tanstack/table-core";
import { ArrowDown, ArrowUp } from "lucide-react";
import type { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { OpeningHourResponseDTO } from "@/client/private";
import { updateOrderOpeningHoursMutation } from "@/client/private/@tanstack/react-query.gen";
import { Button } from "@/components/ui/button";

interface OpeningHourTableOrderCellProps {
  row: Row<OpeningHourResponseDTO>;
  onDataInvalidated: () => void;
  buttonConfig: {
    showUp: boolean;
    showDown: boolean;
  };
}

export const OpeningHourTableOrderCell = ({
  row,
  onDataInvalidated,
  buttonConfig,
}: OpeningHourTableOrderCellProps) => {
  const { t } = useTranslation();
  const update = useMutation({
    ...updateOrderOpeningHoursMutation(),
  });

  const handleOrderUpdate = (orderOperation: "INCREASE" | "DECREASE") => {
    return (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      update.mutate(
        {
          path: { id: row.original.id, orderOperation: orderOperation },
        },
        {
          onSuccess: () => {
            onDataInvalidated();
            toast.success(t("opening_hours.order_update_success"));
          },
          onError: () => toast.error(t("opening_hours.order_update_error")),
        },
      );
    };
  };

  return (
    <div className="space-x-1">
      <Button
        variant="outline"
        size="icon"
        onClick={handleOrderUpdate("INCREASE")}
        disabled={!buttonConfig.showUp}
        className="disabled:invisible"
      >
        <ArrowUp className="size-4" />
      </Button>
      <Button
        variant="outline"
        size="icon"
        onClick={handleOrderUpdate("DECREASE")}
        disabled={!buttonConfig.showDown}
        className="disabled:invisible"
      >
        <ArrowDown className="size-4" />
      </Button>
    </div>
  );
};
