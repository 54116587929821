/**
 * This is the only file where constructing Date and TZDate objects is allowed.
 * This is the only file where formatting with date-fns format is allowed.
 */
import { TZDate, tz } from "@date-fns/tz";
import type { FormatOptions } from "date-fns";
import { format, parseISO } from "date-fns";
import { hourNotation } from "@/client/private";
import type { DateTimeFormat } from "@/types/date-time";

const utcZoneId = "UTC";
const utcZone = tz(utcZoneId);

export function formatDateZone(
  zoneId: string,
  date: string | number | Date,
  dateTimeFormat: DateTimeFormat,
  locale?: FormatOptions["locale"],
  hourNotation?: hourNotation,
) {
  const formatString = dateTimeFormatToFormatString(
    dateTimeFormat,
    hourNotation,
  );
  return format(date, formatString, { in: tz(zoneId), locale });
}

/**
 * Creates a date in the given zone.
 * The date time parts are 'wall' time.
 */
export function createDateZone(
  zoneId: string,
  year: number,
  month: number,
  day: number,
  hours: number = 0,
  minutes: number = 0,
) {
  return new TZDate(year, month, day, hours, minutes, zoneId);
}

/**
 * Converts a date to the given zone. The input date and output date will be the same in UTC.
 */
export function convertDateZone(zoneId: string, date: Date) {
  return new TZDate(date, zoneId);
}

export function formatDateUtc(
  date: string | number | Date,
  dateTimeFormat: DateTimeFormat,
  hourNotation?: hourNotation,
) {
  const formatString = dateTimeFormatToFormatString(
    dateTimeFormat,
    hourNotation,
  );
  return format(date, formatString, { in: utcZone });
}

export function createDateUtc(
  year: number,
  month: number,
  day: number,
  hours: number = 0,
  minutes: number = 0,
) {
  return createDateZone(utcZoneId, year, month, day, hours, minutes);
}

export function parseJsonDateUtc(date: string) {
  return parseISO(date, { in: utcZone });
}

export function nowZone(zoneId: string) {
  const localDate = new Date();
  return createDateZone(
    zoneId,
    localDate.getFullYear(),
    localDate.getMonth(),
    localDate.getDate(),
    localDate.getHours(),
    localDate.getMinutes(),
  );
}

export function dateTimeFormatToFormatString(
  dateTimeFormat: DateTimeFormat,
  companyHourNotation?: hourNotation,
): string {
  const twelveHourFormat = "hh:mm a";
  const twentyFourHourFormat = "HH:mm";

  const companyTimeFormat =
    companyHourNotation === hourNotation.TWELVEHOURS
      ? twelveHourFormat
      : companyHourNotation === hourNotation.TWENTYFOURHOURS
        ? twentyFourHourFormat
        : "p";

  switch (dateTimeFormat) {
    case "time":
      return companyTimeFormat;
    case "time12H":
      return twelveHourFormat;
    case "time24H":
      return twentyFourHourFormat;
    case "time24HNoSeparator":
      return "HHmm";
    case "dateShort":
      return "PP";
    case "dateLong":
      return "PPP";
    case "dateTimeShort":
      return "PP " + companyTimeFormat;
    case "dateTimeLong":
      return "PPP " + companyTimeFormat;
    case "dateTimeWithWeekday":
      return "PPPP " + companyTimeFormat;
    case "dayOfMonthShort":
      return "dd MMM";
    case "dayOfMonthLong":
      return "eeee, do MMMM";
    case "ISODate":
      return "yyyyMMdd";
    case "ISODateTime":
      return "yyyyMMdd'T'HHmmss'Z'";
  }
}
