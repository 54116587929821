import { Breadcrumbs } from "@/components/layout/Breadcrumbs.tsx";
import { MobileSideMenu } from "@/components/layout/MobileSideMenu.tsx";
import { ProfileMenu } from "@/components/layout/ProfileMenu.tsx";
import ThemeToggle from "@/components/theme-toggle/theme-toggle.tsx";

export const TopBar = () => {
  return (
    <header className="sticky top-0 z-30 flex shrink-0 h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
      <MobileSideMenu />
      <Breadcrumbs />
      <div className="relative ml-auto flex-1 md:grow-0">
        {/*<Search className="absolute left-2.5 top-2.5 size-4 text-muted-foreground" />*/}
        {/*<Input*/}
        {/*	type="search"*/}
        {/*	placeholder="Search..."*/}
        {/*	className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"*/}
        {/*/>*/}
      </div>
      <ProfileMenu />
      <ThemeToggle />
    </header>
  );
};
