import { subMinutes } from "date-fns";
import type { GridLayout, TimeSpan } from "@/components/planner/grid-layout";
import { cn } from "@/lib/utils";

type TimeColumnProps = {
  gridLayout: GridLayout;
  timeSpan: TimeSpan;
};

export function TimeColumn({ gridLayout, timeSpan }: TimeColumnProps) {
  const isBottomRightCell = gridLayout.isLastTimeSlot(
    subMinutes(timeSpan.until, gridLayout.zoomLevel.slotSizeInMinutes),
  );
  const rightCellBorder = isBottomRightCell ? "rounded-br-md" : "";

  const laneCount = gridLayout.getRowCount();
  const columnStyle = gridLayout.getTimeSpanColumnStyle(timeSpan);

  return (
    <div
      className={cn("rounded-md bg-gray-400/25", rightCellBorder)}
      style={{
        ...columnStyle,
        gridRow: `1 / span ${laneCount}`,
      }}
    ></div>
  );
}
