import { addMinutes, addYears } from "date-fns";
import { useTranslation } from "react-i18next";
import { RRule } from "rrule";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useDateTime } from "@/hooks/use-date-time";

type PatternResultsProps = {
  className?: string;
  rule: string;
  startDate: Date;
  endDate?: Date;
};

export function PatternResults({
  className,
  rule,
  startDate,
  endDate,
}: PatternResultsProps) {
  const { t } = useTranslation();
  const { formatDate, today, createDate } = useDateTime();

  const hasEndDate = endDate != null;
  const end = hasEndDate ? endDate : addYears(startDate, 5);

  // Format the start date without time zone
  const dtStart = `DTSTART:${formatDate(startDate, "ISODateTime")}`;

  const rrule = new RRule({
    ...RRule.fromString(`${dtStart}\n${rule}`).origOptions,
    until: end,
  });

  // rrule ignores time zones, so we have to adjust the dates produced by it
  const ruleOccurrences = rrule.between(today(), end, true).map((date) => {
    const dateInCompanyTimeZone = createDate(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
    );
    const timeZoneMinutes = dateInCompanyTimeZone.getTimezoneOffset();
    return addMinutes(date, timeZoneMinutes);
  });

  const lastOccurrence = hasEndDate ? ruleOccurrences.at(-1) : undefined;

  return (
    <Alert className={className}>
      <AlertTitle>{t("recurrence.pattern_results.title")}</AlertTitle>
      <AlertDescription>
        {t("recurrence.pattern_results.description", {
          count: Math.min(ruleOccurrences.length, 3),
        })}
        {hasEndDate && " "}
        {hasEndDate && t("recurrence.pattern_results.end_date_included")}
        <ul className="list-disc m-3">
          {ruleOccurrences.slice(0, 3).map((date) => (
            <li key={date.toISOString()}>
              {formatDate(date, "dateTimeWithWeekday")}
            </li>
          ))}
          {ruleOccurrences.length > 3 && lastOccurrence && (
            <>
              <li>...</li>
              <li>{formatDate(lastOccurrence, "dateTimeWithWeekday")}</li>
            </>
          )}
        </ul>
      </AlertDescription>
    </Alert>
  );
}
