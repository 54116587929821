import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { Dashboard } from "@/components/layout/Dashboard.tsx";
import { Skeleton } from "@/components/ui/skeleton";
import { useDateTime } from "@/hooks/use-date-time";

export const Route = createFileRoute("/_auth")({
  beforeLoad: ({ context }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.sub) {
      throw redirect({
        to: "/login",
      });
    }
  },
  component: AuthContainer,
});

function AuthContainer() {
  const { isInitialized } = useDateTime();

  // Only show the dashboard when the user's profile and company are loaded,
  // so that dates are always shown in the company's timezone.
  return (
    <Dashboard>
      {isInitialized ? (
        <Outlet />
      ) : (
        <div className="w-full p-5">
          <Skeleton className="w-full h-10" />
        </div>
      )}
    </Dashboard>
  );
}
