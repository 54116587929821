import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { EmployeeResponseDTO } from "@/client/private";
import { updateEmployeeMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import { PasswordRequirements } from "@/components/auth/PasswordRequirements";
import {
  FormCheckbox,
  FormContainer,
  FormInput,
  FormSelect,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import type { EditProfileSchemaValues } from "@/components/settings/profile/profile-schema";
import { createEditProfileSchema } from "@/components/settings/profile/profile-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { toBackendLanguage, toUiLanguage } from "@/lib/language";

interface EditEmployeeFormProps {
  employee: EmployeeResponseDTO;
}

export function EditEmployeeForm({ employee }: EditEmployeeFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateEmployeeMutation() });

  const schema = createEditProfileSchema(t);
  schema.shape.firstName.optional();

  const form = useZodForm({
    schema,
    values: {
      ...employee,
      language: toUiLanguage(employee.language),
      isAdmin: (employee.role ?? []).includes("ADMIN"),
      passwords: {},
    },
  });

  const handleSubmit = (values: EditProfileSchemaValues) => {
    const { passwords, isAdmin, ...rest } = values;
    const hasPassword = (passwords.password ?? "").trim().length > 0;

    update.mutate(
      {
        path: { id: employee.id },
        body: {
          ...rest,
          password: hasPassword ? passwords.password : undefined,
          role: isAdmin ? ["ADMIN"] : [],
          language: toBackendLanguage(values.language),
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("employees.employee") }),
          );
          navigate({ to: "/employees" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("employees.employee") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer
      title={`${t("employees.employee")} ${employee.firstName} ${employee.lastName}`}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("employees.edit.title")}</CardTitle>
          <CardDescription>{t("employees.edit.description")}</CardDescription>
        </CardHeader>
        <CardContent className="space-y-5">
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="firstName" schema={schema} />
            <FormInput name="lastName" schema={schema} />
            <FormCheckbox
              name="isAdmin"
              schema={schema}
              description={t("settings.profile.role.admin_description")}
            />
            <FormSelect name="language" schema={schema} />
            <FormInput name="email" schema={schema} readOnly={true} />
            <FormInput
              name="password"
              path={["passwords"]}
              type="password"
              schema={schema.shape.passwords}
            />
            <FormInput
              name="confirmation"
              path={["passwords"]}
              type="password"
              schema={schema.shape.passwords}
            />
          </FormContainer>
          <PasswordRequirements allowEmptyPassword={true} />
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
