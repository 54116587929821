import { client } from "@/client/public";
import { getEnvConfig } from "@/lib/get-env-config";

export const setPublicClient = () => {
  const envConfig = getEnvConfig();

  client.setConfig({
    responseType: "json", // needed for hey-client response transformers to work
    timeout: 300_000,
    baseURL: envConfig.api.clientUrl,
  });
};
