import { useQuery } from "@tanstack/react-query";
import {
  getMeOptions,
  getMeQueryKey,
} from "@/client/private/@tanstack/react-query.gen.ts";
import { useAuthenticationContext } from "@/hooks/use-authentication-context.ts";
import { HALF_HOUR } from "@/lib/constants";


export const useProfile = () => {
  const queryKey = getMeQueryKey();
  const { user } = useAuthenticationContext();

  const { data, isLoading } = useQuery({
    ...getMeOptions(),
    queryKey,
    enabled: !!user?.profile?.sub,
    staleTime: HALF_HOUR,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
  });

  return {
    data,
    isLoading,
    queryKey,
  };
};
