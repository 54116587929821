import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { toast } from "sonner";
import type { LineCapacityDTO } from "@/client/private";
import { acceptReservationRequestsMutation } from "@/client/private/@tanstack/react-query.gen";
import type { PlannerReservationRequest } from "@/components/planner/planner.model";
import type { RequestLineMap } from "@/components/planner/requests/request-line-map.model";
import { Button } from "@/components/ui/button";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

type AcceptRequestButtonProps = {
  request: PlannerReservationRequest;
  assignedRequestLines: RequestLineMap;
  onRequestAssigned: () => void;
};

export function AcceptRequestButton({
  request,
  assignedRequestLines,
  onRequestAssigned,
}: AcceptRequestButtonProps) {
  const { mutate } = useMutation({
    ...acceptReservationRequestsMutation(),
  });
  const client = useQueryClient();

  const {
    confirm: confirmUnassignedCapacity,
    Dialog: UnassignedCapacityConfirmDialog,
  } = useConfirmDialog();

  const handleAssignRequest = async () => {
    const lines =
      mapAssignedRequestLinesToLineCapacityDtos(assignedRequestLines);

    if (lines.length === 0) {
      toast.error(t("reservation_requests.no_capacity_assigned"));
      return;
    }

    if (!isAllRequestedCapacityAssigned(request, lines)) {
      const confirmed = await confirmUnassignedCapacity();
      if (!confirmed) {
        return;
      }
    }

    mutate(
      {
        path: { id: request.id },
        body: {
          resourceCapacity: lines,
        },
      },
      {
        onSuccess: () => {
          toast.info(t("reservation_requests.accept_success"));
          client.invalidateQueries();
          onRequestAssigned();
        },
        onError: () => {
          toast.error(t("reservation_requests.accept_error"));
        },
      },
    );
  };

  return (
    <>
      <Button variant="outline" onClick={handleAssignRequest}>
        {t("reservation_requests.accept")}
      </Button>

      <UnassignedCapacityConfirmDialog
        texts={{
          description: t("reservation_requests.assigned_capacity_to_low"),
        }}
      />
    </>
  );
}

const mapAssignedRequestLinesToLineCapacityDtos = (
  assignedRequestLines: RequestLineMap,
): LineCapacityDTO[] => {
  return [...assignedRequestLines.values()]
    .flat()
    .map(({ id, resourceId, capacity }) => ({
      lineId: id,
      resourceId,
      capacity,
    }))
    .filter(({ capacity }) => capacity > 0);
};

const isAllRequestedCapacityAssigned = (
  request: PlannerReservationRequest,
  assignedLines: LineCapacityDTO[],
) => {
  const capacityMatches = request.line.every((requestLine) => {
    const assignedCapacity = assignedLines
      .filter((line) => line.lineId === requestLine.id)
      .reduce((acc, { capacity }) => acc + capacity, 0);
    return assignedCapacity === requestLine.capacity;
  });

  return capacityMatches;
};
