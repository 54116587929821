import { CalendarClock, Edit } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequest,
  PlannerResource,
} from "@/components/planner/planner.model";
import { AcceptRequestButton } from "@/components/planner/requests/AcceptRequestButton";
import { DeclineRequestButton } from "@/components/planner/requests/DeclineRequestButton";
import { EditReservationRequest } from "@/components/planner/requests/EditReservationRequest";
import { ReservationRequestLine } from "@/components/planner/requests/ReservationRequestLine";
import type { RequestLineMap } from "@/components/planner/requests/request-line-map.model";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Sheet } from "@/components/ui/sheet";

type ReservationRequestProps = {
  resources: PlannerResource[];
  request: PlannerReservationRequest;
  onCalendarSyncClick: (request: PlannerReservationRequest) => void;
  onAssignedRequestLinesChange: (
    assignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => void;
};

export function ReservationRequest({
  resources,
  request,
  onCalendarSyncClick,
  onAssignedRequestLinesChange,
}: ReservationRequestProps) {
  const { t } = useTranslation();
  const [assignedRequestLines, setAssignedRequestLines] =
    useState<RequestLineMap>(new Map());
  const [editRequest, setEditRequest] =
    useState<PlannerReservationRequest | null>(null);

  const handleResourceAssignment = (
    lineId: string,
    newAssignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => {
    const newMap = new Map(assignedRequestLines);
    newMap.set(lineId, newAssignedRequestLines);
    setAssignedRequestLines(newMap);
    onAssignedRequestLinesChange([...newMap.values()].flat());
  };

  const resetAssignedRequestLines = () => {
    setAssignedRequestLines(new Map());
    onAssignedRequestLinesChange([]);
  };

  return (
    <>
      <div className="flex gap-2">
        <Button
          variant={"secondary"}
          size={"icon"}
          onClick={() => onCalendarSyncClick(request)}
          title={t("reservation_requests.sync_calendar")}
        >
          <CalendarClock className="size-4" />
        </Button>
        <Button
          variant={"secondary"}
          size={"icon"}
          title={t("reservation_requests.edit_request")}
          onClick={() => setEditRequest(request)}
        >
          <Edit className="size-4" />
        </Button>
      </div>

      <Sheet
        open={editRequest !== null}
        onOpenChange={() => setEditRequest(null)}
      >
        {editRequest && (
          <EditReservationRequest
            request={editRequest}
            onClose={() => setEditRequest(null)}
          />
        )}
      </Sheet>

      {request.line.map((line) => {
        const availableResources = resources.filter((resource) =>
          resource.bookableId.includes(line.bookableId),
        );

        return (
          <Card key={line.id}>
            <CardHeader>
              <div className="flex flex-row justify-between">
                <span>{line.bookableName}</span>
                <span>{`${line.capacity} ${line.capacityLabel}`}</span>
              </div>
            </CardHeader>
            <CardContent>
              <ReservationRequestLine
                requestLine={line}
                availableResources={availableResources}
                onResourceAssignment={(assigned) =>
                  handleResourceAssignment(line.id, assigned)
                }
              />
            </CardContent>
          </Card>
        );
      })}

      <div className="flex flex-wrap justify-between">
        <DeclineRequestButton
          request={request}
          onRequestDeclined={resetAssignedRequestLines}
        />

        <AcceptRequestButton
          request={request}
          assignedRequestLines={assignedRequestLines}
          onRequestAssigned={resetAssignedRequestLines}
        />
      </div>
    </>
  );
}
