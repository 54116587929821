import type { MouseEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationRequest,
} from "@/components/planner/planner.model";
import { ReservationRequest } from "@/components/planner/requests/ReservationRequest";
import { useBookableResourceRequestData } from "@/components/planner/requests/use-bookable-resource-request-data";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { useDateTime } from "@/hooks/use-date-time";

type ReservationRequestsProps = {
  onHoveredRequestsChange: (
    hoveredRequests: PlannerReservationRequest[],
  ) => void;
  onAssignedRequestsChange: (
    assignedRequests: AssignedPlannerReservationRequestLine[],
  ) => void;
  onCalendarSyncClick: (request: PlannerReservationRequest) => void;
};

export function ReservationRequests({
  onHoveredRequestsChange,
  onAssignedRequestsChange,
  onCalendarSyncClick,
}: ReservationRequestsProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateTime();
  const { resources, requests } = useBookableResourceRequestData();

  const [expandedRequests, setExpandedRequests] = useState<string[]>([]);
  const [assignedRequestLines, setAssignedRequestLines] = useState<
    AssignedPlannerReservationRequestLine[]
  >([]);

  const handleRequestMouseEnter = (requestId: string) => {
    const request = requests.find((request) => request.id === requestId);
    if (request) {
      onHoveredRequestsChange([request]);
    }
  };

  const handleRequestMouseOver = (event: MouseEvent) => {
    // Prevent the handler on the document from clearing the hovered requests
    // while we are hovering a request.
    event.stopPropagation();
  };

  const handleResourceAssignment = (
    requestId: string,
    newAssignedRequestLines: AssignedPlannerReservationRequestLine[],
  ) => {
    const updatedLines = assignedRequestLines
      .filter((line) => line.requestId !== requestId)
      .concat(newAssignedRequestLines);
    setAssignedRequestLines(updatedLines);
    onAssignedRequestsChange(updatedLines);
  };

  return (
    <Card className="xl:w-80 w-full">
      <CardHeader>{t("reservation_requests.title")}</CardHeader>
      <CardContent>
        <Accordion
          type="multiple"
          value={expandedRequests}
          onValueChange={setExpandedRequests}
        >
          {requests.map((request) => {
            return (
              <AccordionItem
                key={request.id}
                value={request.id}
                onMouseEnter={() => handleRequestMouseEnter(request.id)}
                onMouseOver={handleRequestMouseOver}
              >
                <AccordionTrigger>
                  <div className="flex flex-col items-start">
                    <span>{request.name}</span>
                    <small>
                      {formatDate(request.startAt, "dateTimeShort")}
                    </small>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="flex flex-col space-y-3">
                  <ReservationRequest
                    request={request}
                    resources={resources}
                    onCalendarSyncClick={onCalendarSyncClick}
                    onAssignedRequestLinesChange={(lines) =>
                      handleResourceAssignment(request.id, lines)
                    }
                  />
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      </CardContent>
    </Card>
  );
}
