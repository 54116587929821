import { useTheme } from "next-themes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ChannelResponseDTO } from "@/client/private";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useProfile } from "@/hooks/use-profile";

type WidgetPreviewProps = {
  channel: ChannelResponseDTO;
};

export function WidgetPreview({ channel }: WidgetPreviewProps) {
  const { t } = useTranslation();
  const { data: profile } = useProfile();
  const { theme } = useTheme();
  const [mode, setMode] = useState<string>("widget");
  const [openingHoursMode, setOpeningHoursMode] = useState("week");

  const lang = profile?.language.toLowerCase();
  const lightOrDarkTheme = theme === "system" ? undefined : theme;

  return (
    <EditItemContainer
      title={`${t("channels.channel")} ${channel.url} ${t("widget.widget").toLowerCase()}`}
      backTo={{ to: "/channels" }}
    >
      <Tabs
        className="sm:w-[32rem] w-full"
        value={mode}
        onValueChange={setMode}
      >
        <TabsList className="grid w-full grid-cols-3 mb-4">
          <TabsTrigger value="widget">{t("widget.widget")}</TabsTrigger>
          <TabsTrigger value="button">{t("widget.button")}</TabsTrigger>
          <TabsTrigger value="openingHours">
            {t("widget.opening_hours")}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="widget">
          <cbrm-reservation
            api-key={channel.apiKey}
            lang={lang}
            color-scheme={lightOrDarkTheme}
            style={{ width: "100%" }}
          />
        </TabsContent>
        <TabsContent value="button">
          <div className="w-full flex justify-center">
            <cbrm-open-reservation-button
              api-key={channel.apiKey}
              lang={lang}
              color-scheme={lightOrDarkTheme}
            />
          </div>
        </TabsContent>
        <TabsContent value="openingHours">
          <div className="flex flex-col items-center gap-4">
            <ToggleGroup
              type="single"
              value={openingHoursMode}
              onValueChange={setOpeningHoursMode}
            >
              <ToggleGroupItem
                value="week"
                aria-label="Show opening hours for the week"
              >
                {t("widget.opening_hours_mode.week")}
              </ToggleGroupItem>
              <ToggleGroupItem
                value="today"
                aria-label="Show today's opening hours"
              >
                {t("widget.opening_hours_mode.today")}
              </ToggleGroupItem>
              <ToggleGroupItem
                value="now"
                aria-label="Show current opening status"
              >
                {t("widget.opening_hours_mode.now")}
              </ToggleGroupItem>
            </ToggleGroup>

            <cbrm-opening-hours
              api-key={channel.apiKey}
              lang={lang}
              mode={openingHoursMode}
            />
          </div>
        </TabsContent>
      </Tabs>
      <LoadWidgetScript
        id="reservation-widget-script-id"
        url="https://widget.cbrm.cloud/cbrm-reservation-widget.js"
      />
      <LoadWidgetScript
        id="opening-hours-widget-script-id"
        url="https://widget.cbrm.cloud/cbrm-opening-hours-widget.js"
      />
    </EditItemContainer>
  );
}

function LoadWidgetScript({ id, url }: { id: string; url: string }) {
  useEffect(() => {
    const getScriptElement = () => document.getElementById(id);

    const createScriptElement = () => {
      const scriptElement = document.createElement("script");
      scriptElement.type = "module";
      scriptElement.async = true;
      scriptElement.src = url;
      scriptElement.id = id;
      return scriptElement;
    };

    if (!getScriptElement()) {
      const scriptElement = createScriptElement();
      document.body.appendChild(scriptElement);
    }

    return () => {
      const scriptToRemove = getScriptElement();
      if (scriptToRemove) {
        document.body.removeChild(scriptToRemove);
      }
    };
  }, [id, url]);

  return null;
}

type ReservationWidgetWebComponentProps = {
  "api-key": string;
  "color-scheme"?: string;
  lang?: string;
};

type OpeningHoursWidgetWebComponentProps = {
  "api-key": string;
  mode?: string;
  lang?: string;
};

declare module "react" {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      ["cbrm-reservation"]: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & ReservationWidgetWebComponentProps,
        HTMLElement
      >;
      ["cbrm-open-reservation-button"]: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & ReservationWidgetWebComponentProps,
        HTMLElement
      >;
      ["cbrm-opening-hours"]: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & OpeningHoursWidgetWebComponentProps,
        HTMLElement
      >;
    }
  }
}
