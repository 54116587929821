import { Link } from "@tanstack/react-router";
import { Trans, useTranslation } from "react-i18next";
import { LoginForm } from "@/components/auth/LoginForm";
import { Authentication } from "@/components/layout/Authentication";


export const Login = () => {
  return (
    <Authentication>
      <Header />
      <LoginForm />
      <Footer />
    </Authentication>
  );
};

function Header() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-2 text-center">
      <h1 className="text-2xl font-semibold tracking-tight">
        {t("login.login_or_register")}
      </h1>
    </div>
  );
}

function Footer() {
  return (
    <p className="px-8 text-center text-sm text-muted-foreground">
      <Trans
        i18nKey="login.you_agree_to_terms_and_conditions"
        components={{
          termsLink: (
            <Link
              to="/terms"
              target="_blank"
              className="underline underline-offset-4 hover:text-primary"
            />
          ),
          privacyLink: (
            <Link
              to="/privacy"
              target="_blank"
              className="underline underline-offset-4 hover:text-primary"
            />
          ),
        }}
      ></Trans>
    </p>
  );
}
