import { t } from "i18next";
import { Trash } from "lucide-react";
import type { BookableResponseDTO } from "@/client/private";
import type { MutableReservationRequestLine } from "@/components/reservations/bookable-capacity/mutable-reservation-request-line.model";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

type BookableCapacityTableProps = {
  bookables: BookableResponseDTO[];
  requestLines: MutableReservationRequestLine[];
  onChange: (requestLines: MutableReservationRequestLine[]) => void;
};

export function BookableCapacityTable({
  bookables,
  requestLines,
  onChange,
}: BookableCapacityTableProps) {
  function removeLine(line: MutableReservationRequestLine) {
    const newLines = [...requestLines.filter((rl) => rl.id !== line.id)];
    onChange(newLines);
  }

  function updateLine(line: MutableReservationRequestLine, capacity: number) {
    const newLines = [
      ...requestLines.map((rl) =>
        rl.id === line.id
          ? {
              ...rl,
              capacity,
            }
          : { ...rl },
      ),
    ];
    onChange(newLines);
  }

  function getBookable(bookableId: string) {
    return bookables.find((b) => b.id === bookableId);
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("bookables.bookable")}</TableHead>
          <TableHead>{t("resources.properties.capacity")}</TableHead>
          <TableHead />
        </TableRow>
      </TableHeader>
      <TableBody>
        {requestLines.map((line) => (
          <TableRow key={`${line.bookableId}`}>
            <TableCell>{getBookable(line.bookableId)?.name}</TableCell>
            <TableCell className="w-fit">
              <div className="flex gap-2 items-center">
                <Input
                  type="number"
                  min="1"
                  className="w-20"
                  value={line.capacity}
                  onChange={(e) => {
                    const capacity = parseInt(e.target.value, 10);
                    updateLine(line, capacity);
                  }}
                />
                {"getBookable(line.bookableId)?.capacityLabel"}
              </div>
            </TableCell>
            <TableCell className="w-fit">
              <Button variant="ghost" onClick={() => removeLine(line)}>
                <Trash size={16} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
