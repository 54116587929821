import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ReservationRequestResponseDTO } from "@/client/private";
import { updateReservationRequestsMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import {
  FormDateTime,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { Form } from "@/components/form/Form";
import { BookableCapacitySelection } from "@/components/reservations/bookable-capacity/BookableCapacitySelection";
import { useMutateReservationRequestLines } from "@/components/reservations/bookable-capacity/use-mutate-reservation-request-lines";
import type { ReservationRequestSchemaValues } from "@/components/reservations/reservation-request-schema";
import { createReservationRequestSchema } from "@/components/reservations/reservation-request-schema";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { useDateTime } from "@/hooks/use-date-time";

interface EditReservationRequestForm {
  request: ReservationRequestResponseDTO;
  onClose: () => void;
}

export function EditReservationRequest({
  request,
  onClose,
}: EditReservationRequestForm) {
  const { t } = useTranslation();
  const { now } = useDateTime();
  const client = useQueryClient();
  const update = useMutation({ ...updateReservationRequestsMutation() });
  const { mutateLines } = useMutateReservationRequestLines();

  const schema = createReservationRequestSchema(t, now());
  const form = useZodForm({
    schema,
    values: {
      window: {
        startAt: request.startAt,
        until: request.until,
      },
      clientName: request.name,
      clientEmail: request.email,
      clientPhoneNumber: request.phoneNumber,
      line: request.line,
    },
  });

  const handleSubmit = (values: ReservationRequestSchemaValues) => {
    update.mutate(
      {
        path: { id: request.id },
        body: {
          startAt: values.window.startAt,
          until: values.window.until,
          name: values.clientName,
          email: values.clientEmail,
          phoneNumber: values.clientPhoneNumber ?? undefined,
        },
      },
      {
        onSuccess: () => {
          mutateLines({
            reservationRequestId: request.id,
            originalLines: request.line,
            mutatedLines: values.line,
            onSuccess,
            onError,
          });
        },
        onError,
      },
    );
  };

  const onSuccess = () => {
    toast.success(
      t("common.edit_success", {
        item: t("reservations.reservation_request"),
      }),
    );

    client.invalidateQueries();
    onClose();
  };

  const onError = () => {
    toast.error(
      t("common.edit_error", {
        item: t("reservations.reservation_request"),
      }),
    );

    onClose();
  };

  return (
    <FormProvider {...form}>
      <SheetContent className="w-full sm:max-w-full lg:w-[50rem] overflow-y-auto space-y-5">
        <SheetHeader>
          <SheetTitle>{`${t("reservations.reservation_request")} ${request.name}`}</SheetTitle>
          <SheetDescription>
            {t("reservations.edit_request.description")}
          </SheetDescription>
        </SheetHeader>

        <Card>
          <CardHeader>
            <CardTitle>{t("reservations.reservation_request")}</CardTitle>
          </CardHeader>
          <CardContent className="space-y-5">
            <Form form={form} onSubmit={handleSubmit}>
              <FormDateTime
                name="startAt"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormDateTime
                name="until"
                path={["window"]}
                schema={schema.shape.window}
              />
              <FormInput name="clientName" schema={schema} />
              <FormInput name="clientEmail" schema={schema} />
              <FormInput name="clientPhoneNumber" schema={schema} />
            </Form>
          </CardContent>
        </Card>
        <BookableCapacitySelection />

        <SheetFooter>
          <SheetClose asChild>
            <FormSubmit form={form} mutation={update}>
              {t("common.save")}
            </FormSubmit>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </FormProvider>
  );
}
