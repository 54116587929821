import type { ComponentProps } from "react";
import { useController } from "react-hook-form";
import { TimeSelect } from "@/components/common/TimeSelect";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import { DatePicker } from "@/components/ui/date-picker";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { useDateTime } from "@/hooks/use-date-time";

type FormDateTimeProps<T extends ZodShape> = Partial<
  ComponentProps<typeof DatePicker>
> &
  FormFieldProps<T>;

export function FormDateTime<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  ...props
}: FormDateTimeProps<T>) {
  const fieldName = getFormFieldName(path, name);
  const {
    field: { onChange, onBlur, value },
  } = useController({ name: fieldName });
  const { formatDate, setTime } = useDateTime();

  const { label, isRequired } = getSchemaProperties(schema, name);
  const time = formatDate(value, "time24H");

  const handleChange = (date: Date | undefined) => {
    onChange(date);
    onBlur(); // This is needed to trigger validation if using onTouched mode
  };

  const handleDateChange = (newDate: Date | undefined) => {
    if (!newDate) {
      handleChange(undefined);
      return;
    }

    handleChange(setTime(newDate, time));
  };

  const handleTimeChange = (newTime: string) => {
    handleChange(setTime(value, newTime));
  };

  return (
    <FormField
      name={fieldName}
      render={() => (
        <FormItem>
          <FormItemLabel label={label} isRequired={isRequired} />
          <FormControl>
            <div className="flex flex-wrap gap-2">
              <DatePicker date={value} {...props} setDate={handleDateChange} />
              <TimeSelect value={time} onChange={handleTimeChange} />
            </div>
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
