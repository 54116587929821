import { useFormContext } from "react-hook-form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import { Checkbox } from "@/components/ui/checkbox";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Skeleton } from "@/components/ui/skeleton";

type FormCheckboxGroupProps<T extends ZodShape> = FormFieldProps<T> & {
  options: { title: string; value: string }[];
  isPending?: boolean;
  hideLabel?: boolean;
};

export function FormCheckboxGroup<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  options,
  isPending,
  hideLabel,
}: FormCheckboxGroupProps<T>) {
  const form = useFormContext();

  const { label, isRequired } = getSchemaProperties(schema, name);
  const formFieldName = getFormFieldName(path, name);

  if (isPending) {
    return <Skeleton />;
  }

  return (
    <FormField
      name={formFieldName}
      control={form.control}
      render={() => (
        <FormItem>
          <div className={!hideLabel || description ? "mb-3" : ""}>
            {!hideLabel && (
              <FormItemLabel label={label} isRequired={isRequired} />
            )}
            <FormDescription>{description}</FormDescription>
          </div>
          {options.map((option) => (
            <FormField
              key={option.value}
              control={form.control}
              name={formFieldName}
              render={({ field }) => {
                return (
                  <FormItem
                    key={option.value}
                    className="flex flex-row items-start space-x-3 space-y-0"
                  >
                    <FormControl>
                      <Checkbox
                        checked={field.value?.includes(option.value)}
                        onCheckedChange={(checked) => {
                          return checked
                            ? field.onChange([...field.value, option.value])
                            : field.onChange(
                                field.value?.filter(
                                  (value: string) => value !== option.value,
                                ),
                              );
                        }}
                      />
                    </FormControl>
                    <FormLabel className="font-normal">
                      {option.title}
                    </FormLabel>
                  </FormItem>
                );
              }}
            />
          ))}
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
