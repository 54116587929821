import type { Row } from "@tanstack/react-table";
import { useDateTime } from "@/hooks/use-date-time";
import type { DateTimeFormat } from "@/types/date-time";

interface DataTableDateCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
  format?: DateTimeFormat;
}

export const DataTableDateCell = <T,>({
  row,
  name,
  format = "dateLong",
}: DataTableDateCell<T>) => {
  const { formatDate } = useDateTime();
  const date = row.getValue<Date>(name);
  return date ? <span>{formatDate(date, format)}</span> : null;
};
