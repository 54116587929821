import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import {
  bookableDurationType,
  type BookableDetailResponseDTO,
} from "@/client/private";
import { updateBookablesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import {
  createBookableSchema,
  timeSlotAssignmentType,
} from "@/components/bookables/bookable-schema";
import { BookableCapacityItems } from "@/components/bookables/capacity-items/BookableCapacityItems";
import { ChannelSelection } from "@/components/bookables/channel-selection/ChannelSelection";
import { getDuration } from "@/components/bookables/duration-capacity-type";
import { ResourceSelection } from "@/components/bookables/resource-selection/ResourceSelection";
import {
  FormContainer,
  FormDuration,
  FormInput,
  FormSubmit,
  FormTextArea,
  useZodForm,
} from "@/components/form";
import { FormRadioGroupWithDescription } from "@/components/form/fields/FormRadioGroupWithDescription";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface EditBookableFormProps {
  bookable: BookableDetailResponseDTO;
}

export function EditBookableForm({ bookable }: EditBookableFormProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const update = useMutation({ ...updateBookablesMutation() });

  const schema = createBookableSchema(t);
  const form = useZodForm({
    schema,
    values: {
      ...bookable,
      channelId: bookable.channel?.map((c) => c.id) ?? [],
      resourceId: bookable.resource?.map((r) => r.id) ?? [],
      bookableDurationType: bookable.bookableDurationType,
      capacityBlockType: bookable.capacityBlockType,
      duration: getDuration(bookable.duration, bookable.bookableDurationType),
      bookableCapacityItems: [
        {
          id: "fake-id-1",
          name: "Volwassenen",
        },
        {
          id: "fake-id-2",
          name: "Kinderen 0-16 jaar",
        },
      ], // TODO
      maxCapacity: 1, // TODO
      timeSlotAssignmentType: timeSlotAssignmentType.SINGLE, // TODO
    },
  });

  const durationType = form.watch("bookableDurationType");

  const handleSubmit = (values: BookableSchemaValues) => {
    update.mutate(
      {
        path: { id: bookable.id },
        body: {
          ...values,
          resourceId: values.resourceId ?? [],
          channelId: values.channelId ?? [],
          storage: [],
        },
      },
      {
        onSuccess: () => {
          toast.success(
            t("common.edit_success", { item: t("bookables.bookable") }),
          );
          navigate({ to: "/bookables" });
        },
        onError: () =>
          toast.error(
            t("common.edit_error", { item: t("bookables.bookable") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={update}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <FormContainer form={form} onSubmit={handleSubmit}>
      <EditItemContainer
        title={`${t("bookables.bookable")} ${bookable.name}`}
        buttons={buttons}
        sidePanel={
          <>
            <BookableCapacityItems />
            <ChannelSelection schema={schema} />
            <ResourceSelection schema={schema} />
          </>
        }
      >
        <Card>
          <CardHeader>
            <CardTitle>{t("bookables.edit.title")}</CardTitle>
            <CardDescription>{t("bookables.edit.description")}</CardDescription>
          </CardHeader>
          <CardContent className="space-y-5">
            <FormInput name="name" schema={schema} />
            <FormTextArea name="description" schema={schema} />
            <FormInput name="capacityLabel" schema={schema} />
            <FormRadioGroupWithDescription
              name="bookableDurationType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.bookable_duration_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.bookable_duration_type.${value.toLowerCase()}_description`,
                )
              }
              isDisabled={(value) => value !== bookableDurationType.FIXED}
            />
            {durationType !== bookableDurationType.UNTIL_OPERATION_CLOSED && (
              <FormDuration
                name="duration"
                schema={schema}
                label={
                  durationType === bookableDurationType.UNTIL_USER_DEFINED
                    ? t("bookables.properties.min_duration")
                    : t("bookables.properties.duration")
                }
              />
            )}
            <FormInput name="maxCapacity" schema={schema} />
            <FormRadioGroupWithDescription
              name="capacityBlockType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.capacity_block_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.capacity_block_type.${value.toLowerCase()}_description`,
                )
              }
            />
            <FormDuration name="slotInterval" schema={schema} />
            <FormRadioGroupWithDescription
              name="timeSlotAssignmentType"
              schema={schema}
              getValueLabel={(value) =>
                t(`bookables.time_slot_assignment_type.${value.toLowerCase()}`)
              }
              getValueDescription={(value) =>
                t(
                  `bookables.time_slot_assignment_type.${value.toLowerCase()}_description`,
                )
              }
              isDisabled={(value) => value !== timeSlotAssignmentType.SINGLE}
            />
          </CardContent>
        </Card>
      </EditItemContainer>
    </FormContainer>
  );
}
