import { useTranslation } from "react-i18next";
import {
  getZoomLevel,
  zoomLevels,
  type ZoomLevel,
} from "@/components/planner/zoom-level";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type ZoomLevelSelectProps = {
  selectedId: ZoomLevel["id"];
  onSelect: (zoomLevel: ZoomLevel) => void;
};

export default function ZoomLevelSelect({
  selectedId,
  onSelect,
}: ZoomLevelSelectProps) {
  const { t } = useTranslation();

  const handleZoomLevelSelect = (id: string) => {
    const zoomLevel = getZoomLevel(id);
    if (zoomLevel) {
      onSelect(zoomLevel);
    }
  };

  return (
    <Select value={selectedId} onValueChange={handleZoomLevelSelect}>
      <SelectTrigger className="w-fit">
        <span>Zoom:</span>
        <SelectValue />
      </SelectTrigger>
      <SelectContent side="top">
        {zoomLevels.map((zoomLevel) => (
          <SelectItem key={zoomLevel.id} value={`${zoomLevel.id}`}>
            {zoomLevel.titleCount == null
              ? t(zoomLevel.titleUnits)
              : t(zoomLevel.titleUnits, { count: zoomLevel.titleCount })}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
