import { useContext } from "react";
import { AuthenticationContext } from "@/context/AuthenticationContext.tsx";

export const useAuthenticationContext = () => {
  const { userManager, user, setUser } = useContext(AuthenticationContext);
  if (setUser === undefined) {
    throw new Error("context not found");
  }

  return { userManager, user, setUser };
};
