import { useFormContext } from "react-hook-form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import type { FormFieldProps, ZodShape } from "@/components/form/types";
import {
  getEnumValues,
  getFormFieldName,
  getSchemaProperties,
} from "@/components/form/utils";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

type FormRadioGroupProps<T extends ZodShape> = FormFieldProps<T> & {
  getValueLabel?: (value: string) => string;
};

export function FormRadioGroup<T extends ZodShape>({
  name,
  path,
  schema,
  description,
  getValueLabel,
}: FormRadioGroupProps<T>) {
  const form = useFormContext();

  const { label, isRequired, fieldSchema } = getSchemaProperties(schema, name);
  const formFieldName = getFormFieldName(path, name);
  const values = getEnumValues(fieldSchema);

  return (
    <FormField
      name={formFieldName}
      control={form.control}
      render={({ field }) => (
        <FormItem>
          <div className="mb-4">
            <FormItemLabel label={label} isRequired={isRequired} />
            <FormDescription>{description}</FormDescription>
          </div>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              value={field.value}
              className="flex flex-col space-y-1"
            >
              {values.map((value) => (
                <FormItem
                  key={value}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={value} />
                  </FormControl>
                  <FormLabel className="font-normal">
                    {getValueLabel?.(value) ?? value}
                  </FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
