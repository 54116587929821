import type { TFunction } from "i18next";
import { z } from "zod";
import { hourNotation } from "@/client/private";

export function createCompanySchema(timezones: string[], t: TFunction) {
  return z.object({
    companyName: z
      .string({
        required_error: t("common.field_required", {
          field: t("settings.general.properties.company_name"),
        }),
      })
      .describe(t("settings.general.properties.company_name"))
      .min(3, {
        message: t("settings.general.company_name_min_length", { min: 3 }),
      })
      .max(160, {
        message: t("settings.general.company_name_max_length", { max: 160 }),
      }),
    timezoneId: z
      .enum(timezones as [string, ...string[]], {
        required_error: t("common.field_required", {
          field: t("settings.general.properties.timezone"),
        }),
      })
      .describe(t("settings.general.properties.timezone")),
    hourNotation: z
      .enum([hourNotation.TWELVEHOURS, hourNotation.TWENTYFOURHOURS], {
        required_error: t("common.field_required", {
          field: t("settings.general.properties.hour_notation"),
        }),
      })
      .describe(t("settings.general.properties.hour_notation")),
  });
}
