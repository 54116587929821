import { useTranslation } from "react-i18next";
import type {
  AssignedPlannerReservationRequestLine,
  PlannerReservationLine,
} from "@/components/planner/planner.model";
import { useDateTime } from "@/hooks/use-date-time";

type ReservationDetailsProps = {
  reservation: PlannerReservationLine | AssignedPlannerReservationRequestLine;
};

export function ReservationDetails({ reservation }: ReservationDetailsProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateTime();

  const popoverContent = [
    [t("bookables.bookable"), reservation.bookableName],
    [
      t("reservations.properties.start_at"),
      formatDate(reservation.startAt, "dateTimeShort"),
    ],
    [
      t("reservations.properties.until"),
      formatDate(reservation.until, "dateTimeShort"),
    ],
    [
      t("resources.properties.capacity"),
      `${reservation.capacity} ${reservation.capacityLabel}`,
    ],
  ];

  return (
    <>
      <span className="font-semibold">{reservation.name}</span>
      <ul className="grid gap-1">
        {popoverContent
          .filter(([, value]) => value)
          .map(([label, value]) => (
            <li key={label} className="flex justify-between">
              <span className="text-muted-foreground">{label}</span>
              <span>{value}</span>
            </li>
          ))}
      </ul>
    </>
  );
}
