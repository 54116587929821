import dayjs from "dayjs";
import type { Duration, DurationUnitType } from "dayjs/plugin/duration";
import { useState } from "react";
import { useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import { getFormFieldName, getSchemaProperties } from "@/components/form/utils";
import { FormControl, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useDayjs } from "../../../hooks/use-dayjs";
import type { FormFieldProps, ZodShape } from "../types";

const defaultPrimaryUnit: DurationUnitType = "minutes";
const defaultSecondaryUnit: DurationUnitType = "seconds";

type FormDurationProps<T extends ZodShape> =
  React.InputHTMLAttributes<HTMLInputElement> &
    FormFieldProps<T> & {
      label?: string;
    };

export function FormDuration<T extends ZodShape>({
  name,
  path,
  schema,
  className,
  label,
}: FormDurationProps<T>) {
  const {
    field: { onChange, onBlur, value },
    fieldState,
  } = useController({ name: getFormFieldName(path, name) });

  const dayjs = useDayjs();
  const { t } = useTranslation();
  const [chosenPrimaryUnit, setChosenPrimaryUnit] =
    useState<DurationUnitType | null>(null);
  const [isPrimaryAmountEmpty, setIsPrimaryAmountEmpty] = useState(false);
  const [isSecondaryAmountEmpty, setIsSecondaryAmountEmpty] = useState(false);

  const { label: schemaLabel, isRequired } = getSchemaProperties(schema, name);

  const duration = prettify(dayjs.duration(value));

  const primaryUnit =
    chosenPrimaryUnit ?? getPrimaryUnit(duration) ?? defaultPrimaryUnit;
  const primaryAmount = getAmount(duration, primaryUnit);
  const secondaryUnit = getSecondaryUnit(primaryUnit) ?? defaultSecondaryUnit;
  const secondaryAmount = getAmount(
    duration.subtract({ [primaryUnit]: primaryAmount }),
    secondaryUnit,
  );

  function onDurationChange({
    newPrimaryAmount = primaryAmount,
    newPrimaryUnit = primaryUnit,
    newSecondaryAmount = secondaryAmount,
    newSecondaryUnit = secondaryUnit,
  }: {
    newPrimaryAmount?: number;
    newPrimaryUnit?: DurationUnitType;
    newSecondaryAmount?: number;
    newSecondaryUnit?: DurationUnitType;
  }) {
    const newDuration = dayjs.duration({
      [newPrimaryUnit]: newPrimaryAmount,
      [newSecondaryUnit]: newSecondaryAmount,
    });

    if (newDuration.asSeconds() !== duration.asSeconds()) {
      const newValue = prettify(newDuration).toISOString();
      onChange(newValue);
    }
  }

  function handlePrimaryAmountChange(value: string) {
    setIsPrimaryAmountEmpty(value === "");
    onDurationChange({ newPrimaryAmount: Number(value) });
  }

  function handlePrimaryUnitChange(newPrimaryUnit: DurationUnitType) {
    const newSecondaryUnit =
      getSecondaryUnit(newPrimaryUnit) ?? defaultSecondaryUnit;
    setChosenPrimaryUnit(newPrimaryUnit);
    onDurationChange({ newPrimaryUnit, newSecondaryUnit });
  }

  function handleSecondaryAmountChange(value: string) {
    setIsSecondaryAmountEmpty(value === "");
    onDurationChange({ newSecondaryAmount: Number(value) });
  }

  function handleSecondaryUnitChange(newSecondaryUnit: DurationUnitType) {
    const index = durationUnits.indexOf(newSecondaryUnit);
    const newPrimaryUnit = durationUnits[index - 1] ?? defaultPrimaryUnit;
    setChosenPrimaryUnit(newPrimaryUnit);
    onDurationChange({ newPrimaryUnit, newSecondaryUnit });
  }

  return (
    <FormItem className={className}>
      <FormItemLabel
        label={label ?? schemaLabel}
        isRequired={isRequired}
        className={fieldState.invalid ? "text-destructive" : ""}
      />
      <div className="flex gap-2 flex-col sm:flex-row">
        <div className="flex flex-1">
          <FormControl>
            <Input
              name={name}
              type="number"
              min="0"
              value={
                isPrimaryAmountEmpty && primaryAmount === 0 ? "" : primaryAmount
              }
              className="rounded-r-none grow focus:z-10"
              onChange={({ target }) => handlePrimaryAmountChange(target.value)}
              onBlur={onBlur}
            />
          </FormControl>
          <FormControl>
            <Select value={primaryUnit} onValueChange={handlePrimaryUnitChange}>
              <SelectTrigger
                className="rounded-l-none border-l-0 shrink w-32 focus:z-10"
                onBlur={onBlur}
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent side="top">
                {durationUnits.slice(0, -1).map((unit) => (
                  <SelectItem key={unit} value={`${unit}`}>
                    {t(`date_time.${unit}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
        </div>
        <div className="flex flex-1">
          <FormControl>
            <Input
              type="number"
              min="0"
              value={
                isSecondaryAmountEmpty && secondaryAmount === 0
                  ? ""
                  : secondaryAmount
              }
              className="rounded-r-none grow focus:z-10"
              onChange={({ target }) =>
                handleSecondaryAmountChange(target.value)
              }
              onBlur={onBlur}
            />
          </FormControl>
          <FormControl>
            <Select
              value={secondaryUnit}
              onValueChange={handleSecondaryUnitChange}
            >
              <SelectTrigger
                className="rounded-l-none border-l-0 shrink w-32 focus:z-10"
                onBlur={onBlur}
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent side="top">
                {durationUnits.slice(1).map((unit) => (
                  <SelectItem key={unit} value={`${unit}`}>
                    {t(`date_time.${unit}`)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormControl>
        </div>
      </div>
      <FormMessage />
    </FormItem>
  );
}

const durationUnits: DurationUnitType[] = [
  "days",
  "hours",
  "minutes",
  "seconds",
];

function getPrimaryUnit(duration: Duration): DurationUnitType | null {
  if (duration.days() > 0) return "days";
  if (duration.hours() > 0) return "hours";
  if (duration.minutes() > 0) return "minutes";
  // Do not return seconds as primary unit, since it
  // is reserved for the smallest secondary unit
  return null;
}

function getAmount(duration: Duration, unit: DurationUnitType) {
  let amount = 0;
  switch (unit) {
    case "days":
      amount = duration.asDays();
      break;
    case "hours":
      amount = duration.asHours();
      break;
    case "minutes":
      amount = duration.asMinutes();
      break;
    case "seconds":
      amount = duration.asSeconds();
      break;
  }

  return Math.floor(amount);
}

function getSecondaryUnit(
  primaryUnit: DurationUnitType,
): DurationUnitType | undefined {
  const index = durationUnits.indexOf(primaryUnit);
  return durationUnits[index + 1];
}

/**
 * Prettify a duration by pushing extra time per unit to the
 * next unit if it does not 'fit' in the current unit.
 * E.g. 1 hour 90 minutes becomes 2 hours 30 minutes.
 */
function prettify(duration: Duration) {
  let seconds = duration.seconds();
  let minutes = duration.minutes();
  let hours = duration.hours();
  let days = duration.days();

  minutes += Math.floor(seconds / 60);
  seconds %= 60;
  hours += Math.floor(minutes / 60);
  minutes %= 60;
  days += Math.floor(hours / 24);
  hours %= 24;

  return dayjs.duration({
    days,
    hours,
    minutes,
    seconds,
  });
}
