import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { Row } from "@tanstack/table-core";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { ChannelResponseDTO } from "@/client/private";
import { deleteChannelsMutation } from "@/client/private/@tanstack/react-query.gen";
import { DataTableActionCell } from "@/components/data-table/cells/data-table-action-cell";

type ActionCellProps = {
  row: Row<ChannelResponseDTO>;
  onDataInvalidated: () => void;
};

export const ChannelTableActionCell = ({
  row,
  onDataInvalidated,
}: ActionCellProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deleteChannel = useMutation({ ...deleteChannelsMutation() });

  const handleEdit = (row: Row<ChannelResponseDTO>) => {
    navigate({ to: `/channels/${row.original.id}` });
  };

  const handleDelete = (row: Row<ChannelResponseDTO>) => {
    deleteChannel.mutateAsync(
      {
        path: { id: row.original.id },
      },
      {
        onSuccess: () => {
          onDataInvalidated();
          toast.success(
            t("common.delete_success", { item: t("channels.channel") }),
          );
        },
        onError: () =>
          toast.error(
            t("common.delete_error", { item: t("channels.channel") }),
          ),
      },
    );
  };

  return DataTableActionCell<ChannelResponseDTO>({
    row,
    actionHandlers: { handleEdit, handleDelete },
    actionItems: [
      {
        key: "widgetPreview",
        onClick: () => navigate({ to: `/channels/${row.original.id}/widget` }),
        text: t("channels.widget_preview"),
      },
    ],
  });
};
