import {
  addMinutes,
  differenceInMinutes,
  startOfDay,
  subMinutes,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import type { Dispatch, SetStateAction } from "react";
import type { GridLayout } from "@/components/planner/grid-layout";
import { Button } from "@/components/ui/button";
import { DatePicker } from "@/components/ui/date-picker";

type NavigationRowProps = {
  gridLayout: GridLayout;
  onSetFirstSlotStart: Dispatch<SetStateAction<Date>>;
};

export function NavigationRow({
  gridLayout,
  onSetFirstSlotStart,
}: NavigationRowProps) {
  const middleTimeSlotIndex = gridLayout.getMiddleSlotIndex();

  // The picker synchronizes with the date of the middle slot.
  const middleTimeSlot = gridLayout.timeSlots[middleTimeSlotIndex];

  const handlePreviousTimeSlot = () => {
    onSetFirstSlotStart((prevDate) =>
      subMinutes(prevDate, gridLayout.zoomLevel.slotSizeInMinutes),
    );
  };

  const handleNextTimeSlot = () => {
    onSetFirstSlotStart((prevDate) =>
      addMinutes(prevDate, gridLayout.zoomLevel.slotSizeInMinutes),
    );
  };

  const handleDateSelect = (selectedDay: Date | undefined) => {
    if (!selectedDay || !middleTimeSlot) {
      return;
    }

    // Calculate the new middle time slot based on the selected day
    // and the current middle time slot.
    const middleSlotMinutes = differenceInMinutes(
      middleTimeSlot,
      startOfDay(middleTimeSlot),
    );
    const newMiddleSlot = addMinutes(selectedDay, middleSlotMinutes);

    // Calculate the new start slot based on the new middle slot and the current start slot.
    const middleSlotDistanceInMinutes =
      middleTimeSlotIndex * gridLayout.zoomLevel.slotSizeInMinutes;
    const newStartSlot = subMinutes(newMiddleSlot, middleSlotDistanceInMinutes);

    onSetFirstSlotStart(newStartSlot);
  };

  return (
    <>
      <div
        style={{
          gridColumn: `${gridLayout.getHeaderColumn(gridLayout.timeSlots[0])} / span 1`,
        }}
        className="flex flex-row gap-1 items-start py-2 w-[200%] row-start-1 row-end-1"
      >
        <Button variant="outline" size="icon" onClick={handlePreviousTimeSlot}>
          <ChevronLeftIcon className="size-4" />
        </Button>
        <Button
          variant="outline"
          size="icon"
          onClick={handleNextTimeSlot}
          className="md:invisible"
        >
          <ChevronRightIcon className="size-4" />
        </Button>
      </div>
      <div
        style={{ gridColumn: `${middleTimeSlotIndex + 1} / span 1` }}
        className="relative flex flex-col items-center p-2 w-[200%] row-start-1 row-end-1"
      >
        <div className="z-10">
          <DatePicker date={middleTimeSlot} setDate={handleDateSelect} />
        </div>
        <div className="absolute bottom-0 h-1/2 w-[1px] border-muted border" />
      </div>
      <div
        style={{
          gridColumn: `${gridLayout.getHeaderColumn(gridLayout.timeSlots.at(-1))} / span 1`,
        }}
        className="flex flex-col items-end py-2 w-[200%] row-start-1 row-end-1"
      >
        <Button
          variant="outline"
          size="icon"
          onClick={handleNextTimeSlot}
          className="invisible md:visible"
        >
          <ChevronRightIcon className="size-4" />
        </Button>
      </div>
    </>
  );
}
