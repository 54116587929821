import type { TFunction } from "i18next";
import { z } from "zod";
import { bookableDurationType, capacityBlockType } from "@/client/private";

export enum timeSlotAssignmentType {
  SINGLE = "SINGLE",
  MULTIPLE = "MULTIPLE",
}

export function createBookableSchema(t: TFunction) {
  return z.object({
    name: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.name"),
        }),
      })
      .describe(t("bookables.properties.name")),
    description: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .min(1, {
        message: t("common.field_required", {
          field: t("bookables.properties.description"),
        }),
      })
      .describe(t("bookables.properties.description")),
    capacityLabel: z
      .string()
      .nullish()
      .describe(t("bookables.properties.capacity_label")),
    maxCapacity: z
      .number({
        required_error: t("common.field_required", {
          field: t("bookables.properties.max_capacity"),
        }),
      })
      .min(1, {
        message: t("bookables.add.max_capacity_min", { min: 1 }),
      })
      .describe(t("bookables.properties.max_capacity")),
    duration: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.duration"),
        }),
      })
      .duration({ message: t("bookables.add.duration_invalid") })
      .describe(t("bookables.properties.duration")),
    slotInterval: z
      .string({
        required_error: t("common.field_required", {
          field: t("bookables.properties.slot_interval"),
        }),
      })
      .duration({ message: t("bookables.add.slot_interval_invalid") })
      .describe(t("bookables.properties.slot_interval")),
    bookableDurationType: z
      .enum([
        bookableDurationType.FIXED,
        bookableDurationType.UNTIL_USER_DEFINED,
        bookableDurationType.UNTIL_OPERATION_CLOSED,
      ])
      .default(bookableDurationType.FIXED)
      .describe(t("bookables.properties.bookable_duration_type")),
    capacityBlockType: z
      .enum([capacityBlockType.SLOT_INTERVAL, capacityBlockType.TOTAL_DURATION])
      .default(capacityBlockType.SLOT_INTERVAL)
      .describe(t("bookables.properties.capacity_block_type")),
    timeSlotAssignmentType: z
      .enum([timeSlotAssignmentType.SINGLE, timeSlotAssignmentType.MULTIPLE])
      .default(timeSlotAssignmentType.SINGLE)
      .describe(t("bookables.properties.time_slot_assignment_type")),
    channelId: z
      .array(z.string())
      .default([])
      .optional()
      .describe(t("bookables.properties.channels")),
    resourceId: z
      .array(z.string())
      .default([])
      .optional()
      .describe(t("bookables.properties.resources")),
    bookableCapacityItems: z
      .array(
        z.object({
          id: z.string(),
          name: z
            .string({
              required_error: t("common.field_required", {
                field: t("bookables.capacity_items.properties.name"),
              }),
            })
            .min(1, {
              message: t("common.field_required", {
                field: t("bookables.capacity_items.properties.name"),
              }),
            }),
        }),
      )
      .refine((items) => items.length > 0, {
        message: t("bookables.capacity_items.required"),
      })
      .default([]),
  });
}

export type BookableSchemaValues = z.infer<
  ReturnType<typeof createBookableSchema>
>;
