import { Trash } from "lucide-react";
import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import type { BookableSchemaValues } from "@/components/bookables/bookable-schema";
import { createBookableSchema } from "@/components/bookables/bookable-schema";
import { FormInput } from "@/components/form";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

export type BookableCapacityItem = {
  id: string;
  name: string;
};

export function BookableCapacityItemTable() {
  const { t } = useTranslation();
  const form = useFormContext<BookableSchemaValues>();

  const schema = useMemo(() => createBookableSchema(t), [t]);
  const items = form.watch("bookableCapacityItems");

  const removeLine = (item: BookableCapacityItem) => {
    const newItems = items.filter((i) => i.id !== item.id);
    form.setValue("bookableCapacityItems", newItems);
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("bookables.capacity_items.properties.name")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {items.map((item, index) => (
          <TableRow key={`${item.id}`}>
            <TableCell>
              <FormInput
                className="w-full"
                hideLabel={true}
                name="name"
                schema={schema}
                path={["bookableCapacityItems", index.toString()]}
              />
            </TableCell>
            <TableCell className="w-fit align-top">
              <Button variant="ghost" onClick={() => removeLine(item)}>
                <Trash size={16} />
              </Button>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
