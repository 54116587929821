import type { CheckedState } from "@radix-ui/react-checkbox";
import { useState } from "react";
import type { ControllerRenderProps } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TimeSelect } from "@/components/common/TimeSelect";
import { FrequencySelect } from "@/components/recurrence/FrequencySelect";
import { IntervalSelect } from "@/components/recurrence/IntervalSelect";
import { PatternResults } from "@/components/recurrence/PatternResults";
import { WeekDaySelect } from "@/components/recurrence/WeekDaySelect";
import { createRecurringRule } from "@/components/recurrence/functions/create-recurring-rule";
import { frequencyHasWeekDays } from "@/components/recurrence/functions/frequency-has-weekdays";
import type { RecurrenceFormData } from "@/components/recurrence/models/recurrence-form-data.model";
import { Checkbox } from "@/components/ui/checkbox";
import { DatePicker } from "@/components/ui/date-picker";
import {
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useDateTime } from "@/hooks/use-date-time";

export function RecurrenceForm() {
  const { t } = useTranslation();
  const { today, setTime } = useDateTime();
  const form = useFormContext<RecurrenceFormData>();
  const [hasEndDateChecked, setHasEndDateChecked] = useState(false);

  const formValue = form.watch();
  const rule = createRecurringRule(formValue, setTime);

  const hasEndDate = hasEndDateChecked || formValue.endDate != null;

  function handleHasEndDateChecked(
    checkedState: CheckedState,
    field: ControllerRenderProps<RecurrenceFormData, "endDate">,
  ) {
    setHasEndDateChecked(checkedState === true);
    if (checkedState !== true) {
      field.onChange(null);
    }
  }

  return (
    <div className="flex gap-5 flex-col">
      <div className="flex flex-wrap gap-3 lg:grid lg:grid-cols-2 items-start">
        <FormField
          control={form.control}
          name="startDate"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("recurrence.start_date")}</FormLabel>
              <DatePicker date={field.value} setDate={field.onChange} />
            </FormItem>
          )}
        ></FormField>

        <FormField
          control={form.control}
          name="endDate"
          render={({ field }) => (
            <FormItem>
              <div className="flex gap-2 pt-2">
                <Checkbox
                  id="hasEndDate"
                  checked={hasEndDate}
                  onCheckedChange={(checked) =>
                    handleHasEndDateChecked(checked, field)
                  }
                />
                <FormLabel className="font-normal" htmlFor="hasEndDate">
                  {t("recurrence.pattern_has_end_date")}
                </FormLabel>
              </div>
              {hasEndDate && (
                <DatePicker date={field.value} setDate={field.onChange} />
              )}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="startTime"
          rules={{ deps: ["endTime"] }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("recurrence.start_time")}</FormLabel>
              <div className="flex items-center gap-2">
                <TimeSelect value={field.value} onChange={field.onChange} />
              </div>
            </FormItem>
          )}
        ></FormField>

        <FormField
          control={form.control}
          name="endTime"
          rules={{
            validate: (value) =>
              formValue.startTime < value ||
              t("recurrence.start_end_time_invalid"),
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("recurrence.end_time")}</FormLabel>
              <div className="flex items-center gap-2">
                <TimeSelect
                  value={field.value}
                  onChange={field.onChange}
                  includeEndOfDay={true}
                />
              </div>
              <FormMessage />

              <FormDescription>
                {t("recurrence.end_time_description")}
              </FormDescription>
            </FormItem>
          )}
        ></FormField>
      </div>

      <div className="flex flex-wrap gap-3 lg:grid lg:grid-cols-2">
        <FormField
          control={form.control}
          name="frequency"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>{t("recurrence.frequency")}</FormLabel>
              <FrequencySelect value={field.value} onChange={field.onChange} />
            </FormItem>
          )}
        ></FormField>

        <FormField
          control={form.control}
          name="interval"
          render={({ field }) => (
            <FormItem className="flex-1">
              <FormLabel>{t("recurrence.interval")}</FormLabel>
              <IntervalSelect
                frequency={formValue.frequency}
                value={field.value}
                onChange={field.onChange}
              />
            </FormItem>
          )}
        ></FormField>
      </div>

      {frequencyHasWeekDays(formValue.frequency) && (
        <FormField
          control={form.control}
          name="weekDays"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("recurrence.days_of_the_week")}</FormLabel>
              <WeekDaySelect value={field.value} onChange={field.onChange} />
            </FormItem>
          )}
        ></FormField>
      )}

      <PatternResults
        className="grow"
        rule={rule}
        startDate={formValue.startDate ?? today()}
        endDate={formValue.endDate}
      />
    </div>
  );
}
