import type { User, UserManager } from "oidc-client-ts";
import { createContext, type Dispatch, type SetStateAction } from "react";

export const AuthenticationContext = createContext<{
  userManager: UserManager;
  user?: User;
  setUser?: Dispatch<SetStateAction<User | undefined>>;
}>({
  userManager: {} as UserManager,
  user: undefined,
  setUser: undefined,
});
