import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { createResourcesMutation } from "@/client/private/@tanstack/react-query.gen.ts";
import {
  FormCheckboxGroup,
  FormContainer,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { useBookableData } from "@/components/resources/list/use-bookable-data";
import type { ResourceSchemaValues } from "@/components/resources/resource-schema";
import { createResourceSchema } from "@/components/resources/resource-schema";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

export function AddResourceForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const create = useMutation({
    ...createResourcesMutation(),
  });
  const { bookableOptions, isBookablesPending } = useBookableData();

  const schema = createResourceSchema(t);
  const form = useZodForm({ schema });

  const handleSubmit = (values: ResourceSchemaValues) => {
    create.mutate(
      {
        body: {
          ...values,
          bookableId: values.bookableId ?? [],
          unavailabilityId: [],
        },
      },
      {
        onSuccess: async () => {
          toast.success(
            t("common.create_success", { item: t("resources.resource") }),
          );
          navigate({ to: "/resources" });
        },
        onError: () =>
          toast.error(
            t("common.create_error", { item: t("resources.resource") }),
          ),
      },
    );
  };

  const buttons = (
    <FormSubmit form={form} mutation={create}>
      {t("common.save")}
    </FormSubmit>
  );

  return (
    <EditItemContainer
      title={t("resources.add.title")}
      backTo={{ to: "/resources" }}
      buttons={buttons}
    >
      <Card>
        <CardHeader>
          <CardTitle>{t("resources.resource")}</CardTitle>
          <CardDescription>{t("resources.add.description")}</CardDescription>
        </CardHeader>
        <CardContent>
          <FormContainer form={form} onSubmit={handleSubmit}>
            <FormInput name="name" schema={schema} />
            <FormInput name="capacity" schema={schema} />
            <FormInput name="shareLimit" schema={schema} />{" "}
            <FormCheckboxGroup
              name="bookableId"
              schema={schema}
              options={bookableOptions}
              isPending={isBookablesPending}
              description={
                bookableOptions.length === 0 && t("resources.add.no_bookables")
              }
            />
          </FormContainer>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
