import { setHours, setMinutes, startOfDay } from "date-fns";
import { enUS, nl } from "date-fns/locale";
import { useCompany } from "@/hooks/use-company";
import { useProfile } from "@/hooks/use-profile";
import {
  convertDateZone,
  createDateZone,
  formatDateUtc,
  formatDateZone,
  nowZone,
} from "@/lib/date-time";
import { getTimeComponents } from "@/lib/time";
import type { DateTimeFormat } from "@/types/date-time";

export type FormatDateFn = (
  date: string | number | Date,
  formatString: DateTimeFormat,
) => string;

export type SetTimeFn = (date: Date, time: string) => Date;

export const useDateTime = () => {
  const { data: profile } = useProfile();
  const { data: company } = useCompany(profile?.companyId);

  const locale = profile?.language === "NL" ? nl : enUS;
  const profileZoneId = company?.timezoneId ?? "Europe/Amsterdam";

  /**
   * Create a date in the profile timezone.
   * Date and time parts are 'wall' time.
   */
  const createDate = (
    year: number,
    month: number,
    day: number,
    hours: number = 0,
    minutes: number = 0,
  ) => {
    return createDateZone(profileZoneId, year, month, day, hours, minutes);
  };

  /**
   * Format a date in the profile timezone.
   */
  const formatDate: FormatDateFn = (
    date: string | number | Date,
    dateTimeFormat: DateTimeFormat,
  ) => {
    return formatDateZone(
      profileZoneId,
      date,
      dateTimeFormat,
      locale,
      company?.hourNotation,
    );
  };

  /**
   * Set the hours and minutes of the given date.
   * @param date The date to set the time on. Can be in any timezone.
   * @param time The time in HH:mm format.
   * @returns A new date with the given time. The date will be in the profile timezone.
   */
  const setTime: SetTimeFn = (date: Date, time: string) => {
    const tzDate = convertDateZone(profileZoneId, date);
    const { hour, minute } = getTimeComponents(time);
    return setMinutes(setHours(tzDate, hour), minute);
  };

  /**
   * Get the current date and time in the profile timezone.
   */
  const now = () => nowZone(profileZoneId);

  /**
   * Get the current date in the profile timezone.
   */
  const today = () => startOfDay(now());

  return {
    isInitialized: profile && company,
    weekStartsOn: locale.options?.weekStartsOn ?? 0,
    createDate,
    formatDate,
    formatDateUtc: (
      date: string | number | Date,
      dateTimeFormat: DateTimeFormat,
    ) => formatDateUtc(date, dateTimeFormat, company?.hourNotation),
    setTime,
    now,
    today,
  };
};
