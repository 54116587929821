import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useAuthenticationContext } from "@/hooks/use-authentication-context.ts";

export const Callback = () => {
  const { userManager, setUser, user } = useAuthenticationContext();
  const nav = useNavigate();

  useEffect(() => {
    userManager
      .signinCallback()
      .then((user) => {
        setUser(user);
      })
      .catch(() => {});
  }, [userManager, setUser]);

  useEffect(() => {
    if (user?.profile.sub) {
      nav({ to: "/planner" });
    }
  }, [user, nav]);

  return <></>;
};
