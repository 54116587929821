import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AnyRouter, RouterProvider } from "@tanstack/react-router";
import { ThemeProvider } from "next-themes";
import { type User, UserManager } from "oidc-client-ts";
import { useEffect, useMemo, useState } from "react";
import { setPrivateClient } from "@/api/private/axios-instance.ts";
import { setPublicClient } from "@/api/public/axios-instance.ts";
import { Toaster } from "@/components/ui/sonner";
import { AuthenticationContext } from "@/context/AuthenticationContext.tsx";
import { useAuthenticationContext } from "@/hooks/use-authentication-context.ts";
import { getEnvConfig } from "@/lib/get-env-config";
import { LanguageProvider } from "@/providers/LanguageProvider.tsx";

type AppProps = { router: AnyRouter };

const App = ({ router }: AppProps) => {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  const envConfig = useMemo(() => getEnvConfig(), []);
  console.log("env", envConfig);

  const userManager = useMemo(
    () =>
      new UserManager({
        authority: envConfig.auth.authority,
        client_id: envConfig.auth.clientId,
        redirect_uri: `${envConfig.auth.redirectUri}/callback`,
        post_logout_redirect_uri: `${envConfig.auth.redirectUri}/login`,
        response_type: "code",
        scope: "openid profile email offline_access",
        ui_locales: "en",
      }),
    [envConfig],
  );

  const queryClient = useMemo(() => new QueryClient(), []);

  useEffect(() => {
    userManager.getUser().then((user) => {
      if (user) {
        setUser(user);
      }
    });

    setPrivateClient(userManager);
    setPublicClient();
    setLoading(false);
  }, [userManager]);

  if (loading) {
    return null;
  }

  return (
    <AuthenticationContext.Provider
      value={{ userManager: userManager, user, setUser }}
    >
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
            <RouteProviderWithToken router={router} />
            <Toaster />
          </ThemeProvider>
        </LanguageProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AuthenticationContext.Provider>
  );
};

const RouteProviderWithToken = ({ router }: AppProps) => {
  const { user } = useAuthenticationContext();
  return (
    <RouterProvider router={router} context={{ sub: user?.profile.sub }} />
  );
};

export default App;
