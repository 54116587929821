import { isSameDay } from "date-fns";
import { useTranslation } from "react-i18next";
import type { ReservationResponseDTO } from "@/client/private";
import { EditItemContainer } from "@/components/layout/EditItemContainer";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useDateTime } from "@/hooks/use-date-time";

interface ViewReservationProps {
  reservation: ReservationResponseDTO;
}

export function ViewReservation({ reservation }: ViewReservationProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateTime();

  return (
    <EditItemContainer
      title={`${t("reservations.reservation")} ${reservation.name}`}
      backTo={{ to: "/reservations" }}
    >
      <Card>
        <CardHeader>
          <CardTitle className="text-xl">
            {formatDate(reservation.startAt, "dateTimeShort")}
            {" - "}
            {isSameDay(reservation.startAt, reservation.until)
              ? ""
              : formatDate(reservation.until, "dateTimeShort")}
            {formatDate(reservation.until, "time")}
          </CardTitle>
        </CardHeader>
        <CardContent>
          <ul className="grid gap-1 max-w-96">
            <li className="flex justify-between">
              <span className="text-muted-foreground">
                {t("reservations.properties.name")}
              </span>
              <span>{reservation.name}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-muted-foreground">
                {t("reservations.properties.email")}
              </span>
              <span>{reservation.email}</span>
            </li>
            <li className="flex justify-between">
              <span className="text-muted-foreground">
                {t("reservations.properties.phone_number")}
              </span>
              <span>{reservation.phoneNumber ?? "-"}</span>
            </li>
            {reservation.canceledAt && (
              <>
                <li className="flex justify-between">
                  <span className="text-muted-foreground">
                    {t("reservations.properties.canceled_at")}
                  </span>
                  <span>
                    {reservation.canceledAt
                      ? formatDate(reservation.canceledAt, "dateTimeLong")
                      : ""}
                  </span>
                </li>
                <li className="flex justify-between">
                  <span className="text-muted-foreground">
                    {t("reservations.properties.cancel_reason")}
                  </span>
                  <span>{reservation.cancelReason}</span>
                </li>
              </>
            )}
          </ul>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <h1 className="text-xl py-5">
            {t("reservations.reserved_resources")}
          </h1>

          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("resources.resource")}</TableHead>
                <TableHead>{t("resources.properties.capacity")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {reservation.line.map((line) => (
                <TableRow key={line.id}>
                  <TableCell>{line.name}</TableCell>
                  <TableCell>
                    {line.capacity} {line.capacityLabel}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </EditItemContainer>
  );
}
