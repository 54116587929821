import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import type { z } from "zod";
import { hourNotation, type GetTimeZonesResponse } from "@/client/private";
import {
  getCompanyOptions,
  getTimeZonesOptions,
  updateCompanyMutation,
} from "@/client/private/@tanstack/react-query.gen.ts";
import { OptionSelect } from "@/components/common/OptionSelect";
import {
  FormContainer,
  FormInput,
  FormSubmit,
  useZodForm,
} from "@/components/form";
import { FormItemLabel } from "@/components/form/common/FormItemLabel";
import { FormRadioGroup } from "@/components/form/fields/FormRadioGroup";
import { createCompanySchema } from "@/components/settings/company/company-schema";
import { SkeletonCard } from "@/components/ui/SkeletonCard.tsx";
import { FormField, FormItem, FormMessage } from "@/components/ui/form";
import { useDateTime } from "@/hooks/use-date-time";

export const CompanySettingsForm = () => {
  const { t } = useTranslation();
  const { formatDate, now } = useDateTime();
  const client = useQueryClient();

  const { data: company, isLoading: isCompanyLoading } = useQuery({
    ...getCompanyOptions(),
  });
  const { data: timezones, isLoading: isTimezoneLoading } = useQuery({
    ...getTimeZonesOptions(),
  });
  const update = useMutation({ ...updateCompanyMutation() });

  const schema = createCompanySchema(timezones ?? [], t);
  const form = useZodForm({
    schema,
    values: {
      companyName: company?.name ?? "",
      timezoneId: company?.timezoneId ?? "",
      hourNotation: company?.hourNotation ?? hourNotation.TWENTYFOURHOURS,
    },
  });

  if (isCompanyLoading) {
    return <SkeletonCard />;
  }

  const handleSubmit = (values: z.infer<typeof schema>) => {
    update.mutate(
      {
        body: {
          name: values.companyName,
          timezoneId: values.timezoneId,
          hourNotation: values.hourNotation,
        },
      },
      {
        onSuccess: () => {
          // Invalidate all queries, because timezone and formatting might have changed
          client.invalidateQueries();
          toast.success(
            t("common.edit_success", { item: t("settings.general.company") }),
          );
        },
        onError: () => {
          toast.error(
            t("common.edit_error", { item: t("settings.general.company") }),
          );
        },
      },
    );
  };

  const currentDateTime = now();

  return (
    <FormContainer form={form} onSubmit={handleSubmit}>
      <FormInput name="companyName" schema={schema} />
      <FormField
        control={form.control}
        name="timezoneId"
        render={({ field }) => (
          <FormItem>
            <FormItemLabel
              label={schema.shape.timezoneId.description}
              isRequired={true}
            />
            <OptionSelect
              className="w-full"
              isLoading={isTimezoneLoading}
              options={getTimezoneOptions(timezones)}
              selectedId={field.value}
              onOptionSelected={(option) => field.onChange(option?.id)}
              title={t("settings.general.timezone")}
            />
            <FormMessage />
          </FormItem>
        )}
      ></FormField>
      <FormRadioGroup
        name="hourNotation"
        schema={schema}
        getValueLabel={(value) =>
          formatDate(
            currentDateTime,
            value === hourNotation.TWELVEHOURS ? "time12H" : "time24H",
          )
        }
      />
      <FormSubmit form={form} mutation={update}>
        {t("common.save")}
      </FormSubmit>
    </FormContainer>
  );
};

function getTimezoneOptions(timezones: GetTimeZonesResponse | undefined) {
  return [...(timezones ?? [])]
    .sort((a, b) => a.localeCompare(b))
    .map((timezone) => ({
      id: timezone,
      name: timezone.replace("_", " "),
    }));
}
