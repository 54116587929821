import type { UseQueryResult } from "@tanstack/react-query";
import { addDays, differenceInDays } from "date-fns";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { OpeningHourPeriodDTO } from "@/client/private";
import { SkeletonCard } from "@/components/ui/SkeletonCard";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { useDateTime } from "@/hooks/use-date-time";
import { cn } from "@/lib/utils";

type UpcomingOpeningHoursProps = UseQueryResult<OpeningHourPeriodDTO[]> & {
  start: Date;
  end: Date;
};

type OpeningSlot = { start: string; end: string };

export function UpcomingOpeningHours({
  data,
  isLoading,
  start,
  end,
}: UpcomingOpeningHoursProps) {
  const { t } = useTranslation();
  const { formatDate } = useDateTime();

  // Group formatted opening hours by day
  const mappedData = useMemo(() => {
    const getDayKey = (date: Date) => formatDate(date, "dayOfMonthLong");
    const days = differenceInDays(end, start);

    const slotsPerDay = new Map<string, OpeningSlot[]>();
    for (let i = 0; i < days; i++) {
      const date = addDays(start, i);
      slotsPerDay.set(getDayKey(date), []);
    }

    return data?.reduce((acc, openingPeriod) => {
      acc.get(getDayKey(openingPeriod.startLocalDateTime))?.push({
        start: formatDate(openingPeriod.startLocalDateTime, "time"),
        end: formatDate(openingPeriod.endLocalDateTime, "time"),
      });

      return acc;
    }, slotsPerDay);
  }, [formatDate, data, start, end]);

  if (isLoading || !mappedData) {
    return <SkeletonCard />;
  }

  const dataEntries = Array.from(mappedData.entries());

  return (
    <Alert className="p-6">
      <AlertTitle>{t("opening_hours.upcoming.title")}</AlertTitle>
      <AlertDescription>
        {t("opening_hours.upcoming.description")}
        <ul className="grid grid-rows-7 grid-flow-col divide-y mt-3">
          {dataEntries.length === 0 && (
            <li>{t("opening_hours.upcoming.no_results")}</li>
          )}
          {dataEntries.map(([day, slots], index) => (
            <li
              key={day}
              className={cn("py-2", index === 7 ? "border-none" : "")}
            >
              {day}
              <ul className="list-disc ms-4">
                {slots.length === 0 && (
                  <li>{t("opening_hours.operation.closed")}</li>
                )}
                {slots.map((slot) => (
                  <li key={slot.start}>
                    {slot.start} - {slot.end}
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </AlertDescription>
    </Alert>
  );
}
