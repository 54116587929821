import type { AxiosError } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import type { UserManager } from "oidc-client-ts";
import { client } from "@/client/private";
import { getEnvConfig } from "@/lib/get-env-config";

export const setPrivateClient = (userManager: UserManager) => {
  const envConfig = getEnvConfig();

  client.setConfig({
    responseType: "json", // needed for hey-client response transformers to work
    timeout: 300_000,
    baseURL: envConfig.api.platformUrl,
  });
  client.instance.interceptors.request.use(
    async (config) => {
      const user = await userManager.getUser();

      if (user?.id_token) {
        config.headers.Authorization = "Bearer " + user.id_token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  const refreshToken = async (failedRequest: AxiosError) => {
    const user = await userManager.getUser();

    if (!user?.refresh_token) {
      await userManager.clearStaleState();
      return;
    }

    try {
      const data = await userManager.signinSilent();

      if (data?.id_token && failedRequest.response) {
        failedRequest.response.config.headers["Authorization"] =
          "Bearer " + data.id_token;
        return;
      }
    } catch {
      await userManager.clearStaleState();
      return Promise.reject(failedRequest);
    }
  };

  createAuthRefreshInterceptor(client.instance, refreshToken);
};
