import type { Row } from "@tanstack/react-table";
import { useDateTime } from "@/hooks/use-date-time";

interface DataTableDateTimeCell<T> {
  row: Row<T>;
  name: Extract<keyof T, string>;
}

export const DataTableDateTimeCell = <T,>({
  row,
  name,
}: DataTableDateTimeCell<T>) => {
  const { formatDate } = useDateTime();
  const date = row.getValue<Date>(name);
  return date ? <span>{formatDate(date, "dateTimeShort")}</span> : null;
};
