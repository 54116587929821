import { Link } from "@tanstack/react-router";
import { User } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { useAuthenticationContext } from "@/hooks/use-authentication-context.ts";
import { useProfile } from "@/hooks/use-profile";

export const ProfileMenu = () => {
  const { t } = useTranslation();
  const { user, userManager } = useAuthenticationContext();
  const { data, isLoading } = useProfile();
  const firstLetter = `${data?.firstName[0]} ${data?.lastName[0]}`;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="overflow-hidden rounded-full"
        >
          <Avatar>
            <AvatarFallback>{isLoading ? "" : firstLetter}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem disabled>
          <User className="mr-2 size-4" />
          <span>{user?.profile.email}</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <Link to="/settings/profile">
          <DropdownMenuItem>{t("settings.profile.title")}</DropdownMenuItem>
        </Link>
        <Link to="/settings">
          <DropdownMenuItem>{t("settings.title")}</DropdownMenuItem>
        </Link>
        <Link to="/support">
          <DropdownMenuItem>{t("support.title")}</DropdownMenuItem>
        </Link>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          onClick={async () => {
            await userManager.signoutRedirect();
          }}
        >
          {t("authentication.logout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
